// This regex matches:
// - YYYY-MM-DD
// - YYYY-MM-DDTHH:mm:ss
// - YYYY-MM-DDTHH:mm:ss.sssZ (with optional milliseconds and timezone)
function isDateString(value: string): boolean {
    const isoDateRegex =
        /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+\-]\d{2}:?\d{2})?)?$/;
    return isoDateRegex.test(value);
}

// A helper function to recursively walk the object and convert date strings
export function convertDates(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map((item) => convertDates(item));
    } else if (obj && typeof obj === 'object') {
        for (const key in obj) {
            if (typeof obj[key] === 'string' && isDateString(obj[key])) {
                obj[key] = new Date(obj[key]);
            } else if (obj[key] && typeof obj[key] === 'object') {
                obj[key] = convertDates(obj[key]);
            }
        }
    }
    return obj;
}
