import { z } from 'zod';
import { OfferDetailLoaderProps } from './offerDetailProps';

export const OfferDetailUrlParamSchema = z
    .object({
        offerId: z.string(),
    })
    .transform(
        (item): OfferDetailLoaderProps => ({
            offer: {
                id: item.offerId,
            },
        })
    );
