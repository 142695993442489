import React from 'react';
import { compose } from 'lodash/fp';
import { Navigate, Outlet, Route } from 'react-router-dom';
import {
    RewardContainerBaseConfig,
    RewardControllerBaseConfig,
    RewardLoaderBaseConfig,
    RewardViewBaseConfig,
} from './base';
import {
    createOfferDetailContainer,
    createOfferDetailController,
    createOfferDetailLoader,
    createOfferDetailView,
    createOfferOverviewContainer,
    createOfferOverviewController,
    createOfferOverviewLoader,
    createOfferOverviewView,
    createRewardLayout,
} from './page';
import {
    creatRewardRootContainer,
    createRewardRootController,
    createRewardRootLoader,
} from './page';
import { createRewardErrorView, createRewardErrorContainer } from './page';
import { RewardRouterNavigation } from './navigate';
import { RewardRouterConfig, RewardRouterInit } from './rewardRouterConfig';
import { RewardRouter, RewardRouterEnhancer } from './rewardRouterInterface';

export function configureRewardRouter(
    config: RewardRouterInit,
    enhancer?: RewardRouterEnhancer
): RewardRouter {
    const enhancers = enhancer ? [enhancer] : [];
    return create(
        {
            ...config,
            strategy: {
                task: {
                    interview(location, task) {
                        return {
                            link: config.dependencies.router.interview(location, task),
                        };
                    },
                    form(location, task) {
                        return {
                            link: config.dependencies.router.form(location, task),
                        };
                    },
                    onboarded(location, task) {
                        return {
                            link: null,
                        };
                    },
                },
            },
            provider: {
                createOfferOverviewController: createOfferOverviewController,
                createOfferDetailController: createOfferDetailController,
            },
        },
        compose(...enhancers)
    );
}

function create(
    config: RewardRouterConfig,
    enhancer?: RewardRouterEnhancer
): RewardRouter {
    if (enhancer) {
        return enhancer(create)(config);
    }

    const navigation: RewardRouterNavigation = {
        offer(offer) {
            return {
                to: `/${config.mount}/offers/${offer.id}`,
            };
        },
    };

    const containerConfig: RewardContainerBaseConfig = {
        UI: config.UI,
        repository: config.client.repository,
        kernel: config.kernel,
        navigation,
        context: {
            root: {
                useContext() {
                    // const account = config.hook.useAccount();
                    // assert(account.scope.auth.user, 'no user found');
                    return {
                        principal: {
                            kind: 'user',
                            id: 123,
                            account: 'account.data.account.id',
                        },
                        organization: null,
                    };
                },
            },
        },
    };

    const loaderConfig: RewardLoaderBaseConfig = {
        repository: config.client.repository,
    };

    const controllerConfig: RewardControllerBaseConfig = {
        dependencies: config.dependencies,
        kernel: config.kernel,
        controller: config.controller,
        infra: config.infra,
        navigation,
        strategy: config.strategy,
    };

    const viewConfig: RewardViewBaseConfig = {
        UI: config.UI,
        Layout: createRewardLayout(),
    };

    const Root = creatRewardRootContainer(
        containerConfig,
        createRewardRootLoader(),
        createRewardRootController(controllerConfig),
        {
            Error: createRewardErrorContainer(
                containerConfig,
                createRewardErrorView(viewConfig)
            ),
        }
    );

    const Offer = {
        Detail: createOfferDetailContainer(
            containerConfig,
            createOfferDetailLoader(loaderConfig),
            config.provider.createOfferDetailController(controllerConfig),
            createOfferDetailView(viewConfig)
        ),
        Overview: createOfferOverviewContainer(
            containerConfig,
            createOfferOverviewLoader(loaderConfig),
            config.provider.createOfferOverviewController(controllerConfig),
            createOfferOverviewView(viewConfig)
        ),
    };

    return {
        navigation: navigation,
        element: (
            <Route
                path={config.mount}
                element={
                    <Root>
                        <Outlet />
                    </Root>
                }
            >
                <Route
                    index={true}
                    element={<Navigate to="offers/discover" replace={true} />}
                />
                <Route path="offers">
                    <Route
                        index={true}
                        element={<Navigate to="discover" replace={true} />}
                    />
                    <Route path="discover" element={<Offer.Overview />} />
                    <Route path=":offerId" element={<Offer.Detail />} />
                </Route>
            </Route>
        ),
    };
}
