import { useMemo } from 'react';
import { assert } from '@varos/util-typescript';
import { keyBySafe } from '../../../../../util';
import { RewardLoaderBaseConfig } from '../../../base';
import { OfferItemAggregate } from '../../../fragment';
import { OfferOverviewLoader } from './offerOverviewInterface';

export function createOfferOverviewLoader(
    config: RewardLoaderBaseConfig
): OfferOverviewLoader {
    const { repository } = config;
    return {
        useLoad(context, props) {
            const partnerQuery = repository.partner.useFind(
                context,
                {},
                {
                    suspense: true,
                }
            );
            const promotionQuery = repository.promotion.useFind(
                context,
                {},
                {
                    suspense: true,
                }
            );
            const offerQuery = repository.offer.useFind(
                context,
                {},
                {
                    suspense: true,
                }
            );
            assert(offerQuery.status === 'success', 'expected suspense');
            assert(partnerQuery.status === 'success', 'expected suspense');
            assert(promotionQuery.status === 'success', 'expected suspense');

            const partnerById = useMemo(
                () => keyBySafe(partnerQuery.data, (item) => item.id),
                [partnerQuery.data]
            );

            const promotionByOffer = useMemo(
                () => keyBySafe(promotionQuery.data, (item) => item.offer),
                [promotionQuery.data]
            );

            const all = offerQuery.data.flatMap((item): OfferItemAggregate[] => {
                const partner = partnerById[item.partner];
                if (!partner) {
                    console.warn(`offer partner '${item.partner}' not found`);
                    return [];
                }
                const promotion = promotionByOffer[item.id] ?? null;
                return [
                    {
                        root: item,
                        partner,
                        rewards: null,
                        tasks: null,
                        promotion,
                    },
                ];
            });

            return {
                promotions:
                    all.length === 1
                        ? all
                        : all.filter((candidate) => candidate.promotion !== null),
                search:
                    all.length === 1
                        ? []
                        : all.filter((candidate) => candidate.promotion === null),
                // search: [],
            };
        },
    };
}
