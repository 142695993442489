import { createEligibilityRepository } from './eligibility';
import { createRewardOfferRepository } from './offer';
import { createRewardPartnerRepository } from './partner';
import { createRewardProductRepository } from './product';
import { createPromotionRepository } from './promotion';
import { RewardClientConfig, RewardClientInit } from './rewardConfig';
import {
    RewardClient,
    RewardClientEnhancer,
    RewardRepositories,
} from './rewardInterface';

export function createRewardClient(
    config: RewardClientInit,
    enhancer?: RewardClientEnhancer
) {
    return create(
        {
            ...config,
            provider: {
                createProductRepository: createRewardProductRepository,
                createOfferRepository: createRewardOfferRepository,
                createPartnerRepository: createRewardPartnerRepository,
                createEligibilityRepository: createEligibilityRepository,
                createPromotionRepository: createPromotionRepository,
            },
        },
        enhancer
    );
}

function create(
    config: RewardClientConfig,
    enhancer?: RewardClientEnhancer
): RewardClient {
    if (enhancer) {
        return enhancer(create)(config);
    }
    const repository: RewardRepositories = {
        product: config.provider.createProductRepository(config.kernel, config.sdk),
        offer: config.provider.createOfferRepository(config.kernel, config.sdk),
        partner: config.provider.createPartnerRepository(config.kernel, config.sdk),
        eligibility: config.provider.createEligibilityRepository(
            config.kernel,
            config.sdk
        ),
        promotion: config.provider.createPromotionRepository(config.kernel, config.sdk),
    };
    return { repository };
}
