/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AdminOffer
 */
export interface AdminOffer {
    /**
     * 
     * @type {string}
     * @memberof AdminOffer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOffer
     */
    'object': AdminOfferObjectEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminOffer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOffer
     */
    'slug': string;
    /**
     * 
     * @type {OfferStatus}
     * @memberof AdminOffer
     */
    'status': OfferStatus;
    /**
     * 
     * @type {string}
     * @memberof AdminOffer
     */
    'category': string;
    /**
     * ID of partner managing offer
     * @type {string}
     * @memberof AdminOffer
     */
    'partner': string;
    /**
     * ID of active version
     * @type {string}
     * @memberof AdminOffer
     */
    'current_version': string;
    /**
     * 
     * @type {Date}
     * @memberof AdminOffer
     */
    'created_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof AdminOffer
     */
    'updated_at': Date;
}

export const AdminOfferObjectEnum = {
    Offer: 'offer'
} as const;

export type AdminOfferObjectEnum = typeof AdminOfferObjectEnum[keyof typeof AdminOfferObjectEnum];

/**
 * 
 * @export
 * @interface AdminOfferCreatePayload
 */
export interface AdminOfferCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof AdminOfferCreatePayload
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOfferCreatePayload
     */
    'category': string;
    /**
     * 
     * @type {OfferStatus}
     * @memberof AdminOfferCreatePayload
     */
    'status'?: OfferStatus;
    /**
     * 
     * @type {Array<AdminOfferVersionCreatePayload>}
     * @memberof AdminOfferCreatePayload
     */
    'versions': Array<AdminOfferVersionCreatePayload>;
    /**
     * ID of partner managing offer
     * @type {string}
     * @memberof AdminOfferCreatePayload
     */
    'partner': string;
}


/**
 * 
 * @export
 * @interface AdminOfferList200Response
 */
export interface AdminOfferList200Response {
    /**
     * 
     * @type {Array<AdminOffer>}
     * @memberof AdminOfferList200Response
     */
    'data': Array<AdminOffer>;
}
/**
 * 
 * @export
 * @interface AdminOfferVersionCreatePayload
 */
export interface AdminOfferVersionCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof AdminOfferVersionCreatePayload
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOfferVersionCreatePayload
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOfferVersionCreatePayload
     */
    'task_mode': AdminOfferVersionCreatePayloadTaskModeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminOfferVersionCreatePayload
     */
    'reward_mode': AdminOfferVersionCreatePayloadRewardModeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminOfferVersionCreatePayload
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminOfferVersionCreatePayload
     */
    'caption': string;
    /**
     * 
     * @type {Array<TaskDefinitionCreateRequest>}
     * @memberof AdminOfferVersionCreatePayload
     */
    'tasks': Array<TaskDefinitionCreateRequest>;
    /**
     * 
     * @type {Array<RewardCreateRequest>}
     * @memberof AdminOfferVersionCreatePayload
     */
    'rewards': Array<RewardCreateRequest>;
}

export const AdminOfferVersionCreatePayloadTaskModeEnum = {
    All: 'all',
    Any: 'any'
} as const;

export type AdminOfferVersionCreatePayloadTaskModeEnum = typeof AdminOfferVersionCreatePayloadTaskModeEnum[keyof typeof AdminOfferVersionCreatePayloadTaskModeEnum];
export const AdminOfferVersionCreatePayloadRewardModeEnum = {
    All: 'all',
    Any: 'any'
} as const;

export type AdminOfferVersionCreatePayloadRewardModeEnum = typeof AdminOfferVersionCreatePayloadRewardModeEnum[keyof typeof AdminOfferVersionCreatePayloadRewardModeEnum];

/**
 * 
 * @export
 * @interface AdminVersion
 */
export interface AdminVersion {
    /**
     * 
     * @type {string}
     * @memberof AdminVersion
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminVersion
     */
    'object': AdminVersionObjectEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminVersion
     */
    'key': string;
    /**
     * 
     * @type {TaskMode}
     * @memberof AdminVersion
     */
    'task_mode': TaskMode;
    /**
     * 
     * @type {RewardMode}
     * @memberof AdminVersion
     */
    'reward_mode': RewardMode;
    /**
     * 
     * @type {string}
     * @memberof AdminVersion
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVersion
     */
    'caption': string;
    /**
     * 
     * @type {Array<TaskDefinition>}
     * @memberof AdminVersion
     */
    'tasks': Array<TaskDefinition>;
    /**
     * 
     * @type {Array<Reward>}
     * @memberof AdminVersion
     */
    'rewards': Array<Reward>;
    /**
     * 
     * @type {string}
     * @memberof AdminVersion
     */
    'offer': string;
    /**
     * 
     * @type {Date}
     * @memberof AdminVersion
     */
    'created_at': Date;
}

export const AdminVersionObjectEnum = {
    Version: 'version'
} as const;

export type AdminVersionObjectEnum = typeof AdminVersionObjectEnum[keyof typeof AdminVersionObjectEnum];

/**
 * 
 * @export
 * @interface AdminVersionList200Response
 */
export interface AdminVersionList200Response {
    /**
     * 
     * @type {Array<AdminVersion>}
     * @memberof AdminVersionList200Response
     */
    'data': Array<AdminVersion>;
}
/**
 * 
 * @export
 * @interface Condition
 */
export interface Condition {
    /**
     * 
     * @type {string}
     * @memberof Condition
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Condition
     */
    'operator': ConditionOperatorEnum;
    /**
     * 
     * @type {ConditionValue}
     * @memberof Condition
     */
    'value': ConditionValue;
}

export const ConditionOperatorEnum = {
    Eq: 'eq',
    Gte: 'gte',
    Gt: 'gt',
    Lte: 'lte',
    Lt: 'lt'
} as const;

export type ConditionOperatorEnum = typeof ConditionOperatorEnum[keyof typeof ConditionOperatorEnum];

/**
 * 
 * @export
 * @interface ConditionGroup
 */
export interface ConditionGroup {
    /**
     * 
     * @type {string}
     * @memberof ConditionGroup
     */
    'mode': ConditionGroupModeEnum;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof ConditionGroup
     */
    'conditions': Array<Condition>;
}

export const ConditionGroupModeEnum = {
    All: 'all',
    Any: 'any'
} as const;

export type ConditionGroupModeEnum = typeof ConditionGroupModeEnum[keyof typeof ConditionGroupModeEnum];

/**
 * 
 * @export
 * @interface ConditionValue
 */
export interface ConditionValue {
}
/**
 * 
 * @export
 * @interface DynamicProduct
 */
export interface DynamicProduct {
    /**
     * 
     * @type {string}
     * @memberof DynamicProduct
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DynamicProduct
     */
    'object': DynamicProductObjectEnum;
    /**
     * 
     * @type {string}
     * @memberof DynamicProduct
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DynamicProduct
     */
    'slug': string | null;
    /**
     * 
     * @type {string}
     * @memberof DynamicProduct
     */
    'partner': string;
    /**
     * 
     * @type {string}
     * @memberof DynamicProduct
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof DynamicProduct
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof DynamicProduct
     */
    'kind': DynamicProductKindEnum;
}

export const DynamicProductObjectEnum = {
    Product: 'product'
} as const;

export type DynamicProductObjectEnum = typeof DynamicProductObjectEnum[keyof typeof DynamicProductObjectEnum];
export const DynamicProductKindEnum = {
    Dynamic: 'dynamic'
} as const;

export type DynamicProductKindEnum = typeof DynamicProductKindEnum[keyof typeof DynamicProductKindEnum];

/**
 * 
 * @export
 * @interface Eligibility
 */
export interface Eligibility {
    /**
     * 
     * @type {string}
     * @memberof Eligibility
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Eligibility
     */
    'object': EligibilityObjectEnum;
    /**
     * 
     * @type {string}
     * @memberof Eligibility
     */
    'offer': string;
    /**
     * 
     * @type {EligibilityStatus}
     * @memberof Eligibility
     */
    'status': EligibilityStatus;
    /**
     * 
     * @type {Array<RewardEligibilityInner>}
     * @memberof Eligibility
     */
    'rewards': Array<RewardEligibilityInner>;
    /**
     * 
     * @type {Array<TaskStatusInner>}
     * @memberof Eligibility
     */
    'tasks': Array<TaskStatusInner>;
    /**
     * 
     * @type {string}
     * @memberof Eligibility
     */
    'created_at': string;
}

export const EligibilityObjectEnum = {
    Eligibility: 'eligibility'
} as const;

export type EligibilityObjectEnum = typeof EligibilityObjectEnum[keyof typeof EligibilityObjectEnum];

/**
 * 
 * @export
 * @interface EligibilityCreateRequest
 */
export interface EligibilityCreateRequest {
    /**
     * ID of offer to check
     * @type {string}
     * @memberof EligibilityCreateRequest
     */
    'offer': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EligibilityRewardStatus = {
    Fulfilled: 'fulfilled',
    Unfulfilled: 'unfulfilled'
} as const;

export type EligibilityRewardStatus = typeof EligibilityRewardStatus[keyof typeof EligibilityRewardStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const EligibilityStatus = {
    Eligible: 'eligible',
    Ineligible: 'ineligible'
} as const;

export type EligibilityStatus = typeof EligibilityStatus[keyof typeof EligibilityStatus];


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ErrorResponseDetailsInner>}
     * @memberof ErrorResponse
     */
    'details'?: Array<ErrorResponseDetailsInner>;
}
/**
 * 
 * @export
 * @interface ErrorResponseDetailsInner
 */
export interface ErrorResponseDetailsInner {
    /**
     * 
     * @type {Array<ErrorResponseDetailsInnerPathInner>}
     * @memberof ErrorResponseDetailsInner
     */
    'path': Array<ErrorResponseDetailsInnerPathInner>;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponseDetailsInner
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponseDetailsInner
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface ErrorResponseDetailsInnerPathInner
 */
export interface ErrorResponseDetailsInnerPathInner {
}
/**
 * 
 * @export
 * @interface FixedProduct
 */
export interface FixedProduct {
    /**
     * 
     * @type {string}
     * @memberof FixedProduct
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FixedProduct
     */
    'object': FixedProductObjectEnum;
    /**
     * 
     * @type {string}
     * @memberof FixedProduct
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FixedProduct
     */
    'slug': string | null;
    /**
     * 
     * @type {string}
     * @memberof FixedProduct
     */
    'partner': string;
    /**
     * 
     * @type {string}
     * @memberof FixedProduct
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof FixedProduct
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FixedProduct
     */
    'kind': FixedProductKindEnum;
}

export const FixedProductObjectEnum = {
    Product: 'product'
} as const;

export type FixedProductObjectEnum = typeof FixedProductObjectEnum[keyof typeof FixedProductObjectEnum];
export const FixedProductKindEnum = {
    Fixed: 'fixed'
} as const;

export type FixedProductKindEnum = typeof FixedProductKindEnum[keyof typeof FixedProductKindEnum];

/**
 * 
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'object': OfferObjectEnum;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'caption': string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'slug': string;
    /**
     * 
     * @type {OfferStatus}
     * @memberof Offer
     */
    'status': OfferStatus;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'category': string;
    /**
     * 
     * @type {TaskMode}
     * @memberof Offer
     */
    'task_mode': TaskMode;
    /**
     * 
     * @type {RewardMode}
     * @memberof Offer
     */
    'reward_mode': RewardMode;
    /**
     * 
     * @type {Array<TaskDefinition>}
     * @memberof Offer
     */
    'tasks': Array<TaskDefinition>;
    /**
     * 
     * @type {Array<Reward>}
     * @memberof Offer
     */
    'rewards': Array<Reward>;
    /**
     * ID of partner managing offer
     * @type {string}
     * @memberof Offer
     */
    'partner': string;
    /**
     * ID of applied version of the offer
     * @type {string}
     * @memberof Offer
     */
    'version': string;
    /**
     * 
     * @type {Date}
     * @memberof Offer
     */
    'created_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof Offer
     */
    'updated_at': Date;
}

export const OfferObjectEnum = {
    Offer: 'offer'
} as const;

export type OfferObjectEnum = typeof OfferObjectEnum[keyof typeof OfferObjectEnum];

/**
 * 
 * @export
 * @interface OfferList200Response
 */
export interface OfferList200Response {
    /**
     * 
     * @type {Array<Offer>}
     * @memberof OfferList200Response
     */
    'data': Array<Offer>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OfferStatus = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type OfferStatus = typeof OfferStatus[keyof typeof OfferStatus];


/**
 * 
 * @export
 * @interface Partner
 */
export interface Partner {
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    'object': PartnerObjectEnum;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    'logo_url': string;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    'website_url': string;
}

export const PartnerObjectEnum = {
    Partner: 'partner'
} as const;

export type PartnerObjectEnum = typeof PartnerObjectEnum[keyof typeof PartnerObjectEnum];

/**
 * 
 * @export
 * @interface PartnerCreatePayload
 */
export interface PartnerCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof PartnerCreatePayload
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCreatePayload
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCreatePayload
     */
    'logo_url': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCreatePayload
     */
    'website_url': string;
}
/**
 * 
 * @export
 * @interface PartnerList200Response
 */
export interface PartnerList200Response {
    /**
     * 
     * @type {Array<Partner>}
     * @memberof PartnerList200Response
     */
    'data': Array<Partner>;
}
/**
 * @type Product
 * @export
 */
export type Product = { kind: 'dynamic' } & DynamicProduct | { kind: 'fixed' } & FixedProduct | { kind: 'DynamicProduct' } & DynamicProduct | { kind: 'FixedProduct' } & FixedProduct;

/**
 * @type ProductCreateRequest
 * @export
 */
export type ProductCreateRequest = { kind: 'dynamic' } & ProductDynamicCreateRequest | { kind: 'fixed' } & ProductFixedCreateRequest | { kind: 'ProductDynamicCreateRequest' } & ProductDynamicCreateRequest | { kind: 'ProductFixedCreateRequest' } & ProductFixedCreateRequest;

/**
 * 
 * @export
 * @interface ProductDynamicCreateRequest
 */
export interface ProductDynamicCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductDynamicCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDynamicCreateRequest
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDynamicCreateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDynamicCreateRequest
     */
    'partner': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDynamicCreateRequest
     */
    'kind': ProductDynamicCreateRequestKindEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductDynamicCreateRequest
     */
    'unit': string;
    /**
     * 
     * @type {number}
     * @memberof ProductDynamicCreateRequest
     */
    'amount': number;
}

export const ProductDynamicCreateRequestKindEnum = {
    Dynamic: 'dynamic'
} as const;

export type ProductDynamicCreateRequestKindEnum = typeof ProductDynamicCreateRequestKindEnum[keyof typeof ProductDynamicCreateRequestKindEnum];

/**
 * 
 * @export
 * @interface ProductFixedCreateRequest
 */
export interface ProductFixedCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductFixedCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductFixedCreateRequest
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof ProductFixedCreateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFixedCreateRequest
     */
    'partner': string;
    /**
     * 
     * @type {string}
     * @memberof ProductFixedCreateRequest
     */
    'kind': ProductFixedCreateRequestKindEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductFixedCreateRequest
     */
    'type': string;
}

export const ProductFixedCreateRequestKindEnum = {
    Fixed: 'fixed'
} as const;

export type ProductFixedCreateRequestKindEnum = typeof ProductFixedCreateRequestKindEnum[keyof typeof ProductFixedCreateRequestKindEnum];

/**
 * 
 * @export
 * @interface ProductList200Response
 */
export interface ProductList200Response {
    /**
     * 
     * @type {Array<Product>}
     * @memberof ProductList200Response
     */
    'data': Array<Product>;
}
/**
 * 
 * @export
 * @interface Promotion
 */
export interface Promotion {
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'object': PromotionObjectEnum;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'offer': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'created_at': string;
}

export const PromotionObjectEnum = {
    Promotion: 'promotion'
} as const;

export type PromotionObjectEnum = typeof PromotionObjectEnum[keyof typeof PromotionObjectEnum];

/**
 * 
 * @export
 * @interface PromotionList200Response
 */
export interface PromotionList200Response {
    /**
     * 
     * @type {Array<Promotion>}
     * @memberof PromotionList200Response
     */
    'data': Array<Promotion>;
}
/**
 * 
 * @export
 * @interface Reward
 */
export interface Reward {
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    'object': RewardObjectEnum;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    'product': string;
    /**
     * 
     * @type {number}
     * @memberof Reward
     */
    'quantity': number;
    /**
     * 
     * @type {ConditionGroup}
     * @memberof Reward
     */
    'condition': ConditionGroup | null;
}

export const RewardObjectEnum = {
    Reward: 'reward'
} as const;

export type RewardObjectEnum = typeof RewardObjectEnum[keyof typeof RewardObjectEnum];

/**
 * 
 * @export
 * @interface RewardCreateRequest
 */
export interface RewardCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof RewardCreateRequest
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof RewardCreateRequest
     */
    'product': string;
    /**
     * 
     * @type {number}
     * @memberof RewardCreateRequest
     */
    'quantity'?: number;
    /**
     * 
     * @type {Condition}
     * @memberof RewardCreateRequest
     */
    'condition'?: Condition;
}
/**
 * 
 * @export
 * @interface RewardEligibilityInner
 */
export interface RewardEligibilityInner {
    /**
     * 
     * @type {string}
     * @memberof RewardEligibilityInner
     */
    'key': string;
    /**
     * 
     * @type {EligibilityRewardStatus}
     * @memberof RewardEligibilityInner
     */
    'status': EligibilityRewardStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RewardMode = {
    All: 'all',
    Any: 'any',
    Best: 'best'
} as const;

export type RewardMode = typeof RewardMode[keyof typeof RewardMode];


/**
 * @type TaskDefinition
 * @export
 */
export type TaskDefinition = { type: 'form' } & TaskDefinitionForm | { type: 'interview' } & TaskDefinitionInterview | { type: 'onboarded' } & TaskDefinitionAccountOnboarded | { type: 'TaskDefinitionAccountOnboarded' } & TaskDefinitionAccountOnboarded | { type: 'TaskDefinitionForm' } & TaskDefinitionForm | { type: 'TaskDefinitionInterview' } & TaskDefinitionInterview;

/**
 * 
 * @export
 * @interface TaskDefinitionAccountOnboarded
 */
export interface TaskDefinitionAccountOnboarded {
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionAccountOnboarded
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionAccountOnboarded
     */
    'object': TaskDefinitionAccountOnboardedObjectEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionAccountOnboarded
     */
    'type': TaskDefinitionAccountOnboardedTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionAccountOnboarded
     */
    'display_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionAccountOnboarded
     */
    'description': string | null;
    /**
     * 
     * @type {any}
     * @memberof TaskDefinitionAccountOnboarded
     */
    'config': any | null;
}

export const TaskDefinitionAccountOnboardedObjectEnum = {
    Task: 'task'
} as const;

export type TaskDefinitionAccountOnboardedObjectEnum = typeof TaskDefinitionAccountOnboardedObjectEnum[keyof typeof TaskDefinitionAccountOnboardedObjectEnum];
export const TaskDefinitionAccountOnboardedTypeEnum = {
    Onboarded: 'onboarded'
} as const;

export type TaskDefinitionAccountOnboardedTypeEnum = typeof TaskDefinitionAccountOnboardedTypeEnum[keyof typeof TaskDefinitionAccountOnboardedTypeEnum];

/**
 * 
 * @export
 * @interface TaskDefinitionAccountOnboardedCreateRequest
 */
export interface TaskDefinitionAccountOnboardedCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionAccountOnboardedCreateRequest
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionAccountOnboardedCreateRequest
     */
    'display_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionAccountOnboardedCreateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionAccountOnboardedCreateRequest
     */
    'type': TaskDefinitionAccountOnboardedCreateRequestTypeEnum;
    /**
     * 
     * @type {any}
     * @memberof TaskDefinitionAccountOnboardedCreateRequest
     */
    'config': any | null;
}

export const TaskDefinitionAccountOnboardedCreateRequestTypeEnum = {
    Onboarded: 'onboarded'
} as const;

export type TaskDefinitionAccountOnboardedCreateRequestTypeEnum = typeof TaskDefinitionAccountOnboardedCreateRequestTypeEnum[keyof typeof TaskDefinitionAccountOnboardedCreateRequestTypeEnum];

/**
 * @type TaskDefinitionCreateRequest
 * @export
 */
export type TaskDefinitionCreateRequest = { type: 'form' } & TaskDefinitionFormCreateRequest | { type: 'interview' } & TaskDefinitionInterviewCreateRequest | { type: 'onboarded' } & TaskDefinitionAccountOnboardedCreateRequest | { type: 'TaskDefinitionAccountOnboardedCreateRequest' } & TaskDefinitionAccountOnboardedCreateRequest | { type: 'TaskDefinitionFormCreateRequest' } & TaskDefinitionFormCreateRequest | { type: 'TaskDefinitionInterviewCreateRequest' } & TaskDefinitionInterviewCreateRequest;

/**
 * 
 * @export
 * @interface TaskDefinitionForm
 */
export interface TaskDefinitionForm {
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionForm
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionForm
     */
    'object': TaskDefinitionFormObjectEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionForm
     */
    'type': TaskDefinitionFormTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionForm
     */
    'display_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionForm
     */
    'description': string | null;
    /**
     * 
     * @type {TaskDefinitionFormConfig}
     * @memberof TaskDefinitionForm
     */
    'config': TaskDefinitionFormConfig;
}

export const TaskDefinitionFormObjectEnum = {
    Task: 'task'
} as const;

export type TaskDefinitionFormObjectEnum = typeof TaskDefinitionFormObjectEnum[keyof typeof TaskDefinitionFormObjectEnum];
export const TaskDefinitionFormTypeEnum = {
    Form: 'form'
} as const;

export type TaskDefinitionFormTypeEnum = typeof TaskDefinitionFormTypeEnum[keyof typeof TaskDefinitionFormTypeEnum];

/**
 * 
 * @export
 * @interface TaskDefinitionFormConfig
 */
export interface TaskDefinitionFormConfig {
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionFormConfig
     */
    'form': string;
}
/**
 * 
 * @export
 * @interface TaskDefinitionFormCreateRequest
 */
export interface TaskDefinitionFormCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionFormCreateRequest
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionFormCreateRequest
     */
    'display_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionFormCreateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionFormCreateRequest
     */
    'type': TaskDefinitionFormCreateRequestTypeEnum;
    /**
     * 
     * @type {TaskDefinitionFormConfig}
     * @memberof TaskDefinitionFormCreateRequest
     */
    'config': TaskDefinitionFormConfig;
}

export const TaskDefinitionFormCreateRequestTypeEnum = {
    Form: 'form'
} as const;

export type TaskDefinitionFormCreateRequestTypeEnum = typeof TaskDefinitionFormCreateRequestTypeEnum[keyof typeof TaskDefinitionFormCreateRequestTypeEnum];

/**
 * 
 * @export
 * @interface TaskDefinitionInterview
 */
export interface TaskDefinitionInterview {
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionInterview
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionInterview
     */
    'object': TaskDefinitionInterviewObjectEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionInterview
     */
    'type': TaskDefinitionInterviewTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionInterview
     */
    'display_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionInterview
     */
    'description': string | null;
    /**
     * 
     * @type {TaskDefinitionInterviewConfig}
     * @memberof TaskDefinitionInterview
     */
    'config': TaskDefinitionInterviewConfig;
}

export const TaskDefinitionInterviewObjectEnum = {
    Task: 'task'
} as const;

export type TaskDefinitionInterviewObjectEnum = typeof TaskDefinitionInterviewObjectEnum[keyof typeof TaskDefinitionInterviewObjectEnum];
export const TaskDefinitionInterviewTypeEnum = {
    Interview: 'interview'
} as const;

export type TaskDefinitionInterviewTypeEnum = typeof TaskDefinitionInterviewTypeEnum[keyof typeof TaskDefinitionInterviewTypeEnum];

/**
 * 
 * @export
 * @interface TaskDefinitionInterviewConfig
 */
export interface TaskDefinitionInterviewConfig {
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionInterviewConfig
     */
    'interview': string;
}
/**
 * 
 * @export
 * @interface TaskDefinitionInterviewCreateRequest
 */
export interface TaskDefinitionInterviewCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionInterviewCreateRequest
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionInterviewCreateRequest
     */
    'display_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionInterviewCreateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDefinitionInterviewCreateRequest
     */
    'type': TaskDefinitionInterviewCreateRequestTypeEnum;
    /**
     * 
     * @type {TaskDefinitionInterviewConfig}
     * @memberof TaskDefinitionInterviewCreateRequest
     */
    'config': TaskDefinitionInterviewConfig;
}

export const TaskDefinitionInterviewCreateRequestTypeEnum = {
    Interview: 'interview'
} as const;

export type TaskDefinitionInterviewCreateRequestTypeEnum = typeof TaskDefinitionInterviewCreateRequestTypeEnum[keyof typeof TaskDefinitionInterviewCreateRequestTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const TaskMode = {
    All: 'all',
    Any: 'any'
} as const;

export type TaskMode = typeof TaskMode[keyof typeof TaskMode];


/**
 * 
 * @export
 * @interface TaskStatusInner
 */
export interface TaskStatusInner {
    /**
     * 
     * @type {string}
     * @memberof TaskStatusInner
     */
    'key': string;
    /**
     * 
     * @type {TaskStatusKind}
     * @memberof TaskStatusInner
     */
    'status': TaskStatusKind;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TaskStatusKind = {
    Pending: 'pending',
    Completed: 'completed',
    Error: 'error'
} as const;

export type TaskStatusKind = typeof TaskStatusKind[keyof typeof TaskStatusKind];



/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary admin offer archive
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOfferArchive: async (offerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('adminOfferArchive', 'offerId', offerId)
            const localVarPath = `/admin/offers/{offerId}/archive`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin offer create
         * @param {AdminOfferCreatePayload} [adminOfferCreatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOfferCreate: async (adminOfferCreatePayload?: AdminOfferCreatePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOfferCreatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin offer list
         * @param {number | null} [limit] 
         * @param {OfferStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOfferList: async (limit?: number | null, status?: OfferStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin offer publish
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOfferPublish: async (offerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('adminOfferPublish', 'offerId', offerId)
            const localVarPath = `/admin/offers/{offerId}/publish`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin offer retrieve
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOfferRetrieve: async (offerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('adminOfferRetrieve', 'offerId', offerId)
            const localVarPath = `/admin/offers/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin partner create
         * @param {PartnerCreatePayload} [partnerCreatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPartnerCreate: async (partnerCreatePayload?: PartnerCreatePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerCreatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin partner delete
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPartnerDelete: async (partnerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('adminPartnerDelete', 'partnerId', partnerId)
            const localVarPath = `/admin/partners/{partnerId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin partner list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPartnerList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin partner retrieve
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPartnerRetrieve: async (partnerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('adminPartnerRetrieve', 'partnerId', partnerId)
            const localVarPath = `/admin/partners/{partnerId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin product create
         * @param {ProductCreateRequest} [productCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCreate: async (productCreateRequest?: ProductCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin product delete
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductDelete: async (productId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminProductDelete', 'productId', productId)
            const localVarPath = `/admin/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin product list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin version list
         * @param {string} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVersionList: async (offer: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offer' is not null or undefined
            assertParamExists('adminVersionList', 'offer', offer)
            const localVarPath = `/admin/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offer !== undefined) {
                localVarQueryParameter['offer'] = offer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary admin offer archive
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOfferArchive(offerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminOffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOfferArchive(offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminOfferArchive']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary admin offer create
         * @param {AdminOfferCreatePayload} [adminOfferCreatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOfferCreate(adminOfferCreatePayload?: AdminOfferCreatePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminOffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOfferCreate(adminOfferCreatePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminOfferCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary admin offer list
         * @param {number | null} [limit] 
         * @param {OfferStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOfferList(limit?: number | null, status?: OfferStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminOfferList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOfferList(limit, status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminOfferList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary admin offer publish
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOfferPublish(offerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminOffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOfferPublish(offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminOfferPublish']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary admin offer retrieve
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOfferRetrieve(offerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminOffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOfferRetrieve(offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminOfferRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary admin partner create
         * @param {PartnerCreatePayload} [partnerCreatePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPartnerCreate(partnerCreatePayload?: PartnerCreatePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPartnerCreate(partnerCreatePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminPartnerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary admin partner delete
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPartnerDelete(partnerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPartnerDelete(partnerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminPartnerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary admin partner list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPartnerList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPartnerList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminPartnerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary admin partner retrieve
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPartnerRetrieve(partnerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPartnerRetrieve(partnerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminPartnerRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary admin product create
         * @param {ProductCreateRequest} [productCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCreate(productCreateRequest?: ProductCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCreate(productCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminProductCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary admin product delete
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductDelete(productId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductDelete(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminProductDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary admin product list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminProductList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary admin version list
         * @param {string} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVersionList(offer: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVersionList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVersionList(offer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminVersionList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary admin offer archive
         * @param {AdminApiAdminOfferArchiveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOfferArchive(requestParameters: AdminApiAdminOfferArchiveRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminOffer> {
            return localVarFp.adminOfferArchive(requestParameters.offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin offer create
         * @param {AdminApiAdminOfferCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOfferCreate(requestParameters: AdminApiAdminOfferCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminOffer> {
            return localVarFp.adminOfferCreate(requestParameters.adminOfferCreatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin offer list
         * @param {AdminApiAdminOfferListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOfferList(requestParameters: AdminApiAdminOfferListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminOfferList200Response> {
            return localVarFp.adminOfferList(requestParameters.limit, requestParameters.status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin offer publish
         * @param {AdminApiAdminOfferPublishRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOfferPublish(requestParameters: AdminApiAdminOfferPublishRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminOffer> {
            return localVarFp.adminOfferPublish(requestParameters.offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin offer retrieve
         * @param {AdminApiAdminOfferRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOfferRetrieve(requestParameters: AdminApiAdminOfferRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminOffer> {
            return localVarFp.adminOfferRetrieve(requestParameters.offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin partner create
         * @param {AdminApiAdminPartnerCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPartnerCreate(requestParameters: AdminApiAdminPartnerCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Partner> {
            return localVarFp.adminPartnerCreate(requestParameters.partnerCreatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin partner delete
         * @param {AdminApiAdminPartnerDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPartnerDelete(requestParameters: AdminApiAdminPartnerDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<Partner> {
            return localVarFp.adminPartnerDelete(requestParameters.partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin partner list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPartnerList(options?: RawAxiosRequestConfig): AxiosPromise<PartnerList200Response> {
            return localVarFp.adminPartnerList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin partner retrieve
         * @param {AdminApiAdminPartnerRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPartnerRetrieve(requestParameters: AdminApiAdminPartnerRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<Partner> {
            return localVarFp.adminPartnerRetrieve(requestParameters.partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin product create
         * @param {AdminApiAdminProductCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCreate(requestParameters: AdminApiAdminProductCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.adminProductCreate(requestParameters.productCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin product delete
         * @param {AdminApiAdminProductDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductDelete(requestParameters: AdminApiAdminProductDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.adminProductDelete(requestParameters.productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin product list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductList(options?: RawAxiosRequestConfig): AxiosPromise<ProductList200Response> {
            return localVarFp.adminProductList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin version list
         * @param {AdminApiAdminVersionListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVersionList(requestParameters: AdminApiAdminVersionListRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVersionList200Response> {
            return localVarFp.adminVersionList(requestParameters.offer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminOfferArchive operation in AdminApi.
 * @export
 * @interface AdminApiAdminOfferArchiveRequest
 */
export interface AdminApiAdminOfferArchiveRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminOfferArchive
     */
    readonly offerId: string
}

/**
 * Request parameters for adminOfferCreate operation in AdminApi.
 * @export
 * @interface AdminApiAdminOfferCreateRequest
 */
export interface AdminApiAdminOfferCreateRequest {
    /**
     * 
     * @type {AdminOfferCreatePayload}
     * @memberof AdminApiAdminOfferCreate
     */
    readonly adminOfferCreatePayload?: AdminOfferCreatePayload
}

/**
 * Request parameters for adminOfferList operation in AdminApi.
 * @export
 * @interface AdminApiAdminOfferListRequest
 */
export interface AdminApiAdminOfferListRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminApiAdminOfferList
     */
    readonly limit?: number | null

    /**
     * 
     * @type {OfferStatus}
     * @memberof AdminApiAdminOfferList
     */
    readonly status?: OfferStatus
}

/**
 * Request parameters for adminOfferPublish operation in AdminApi.
 * @export
 * @interface AdminApiAdminOfferPublishRequest
 */
export interface AdminApiAdminOfferPublishRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminOfferPublish
     */
    readonly offerId: string
}

/**
 * Request parameters for adminOfferRetrieve operation in AdminApi.
 * @export
 * @interface AdminApiAdminOfferRetrieveRequest
 */
export interface AdminApiAdminOfferRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminOfferRetrieve
     */
    readonly offerId: string
}

/**
 * Request parameters for adminPartnerCreate operation in AdminApi.
 * @export
 * @interface AdminApiAdminPartnerCreateRequest
 */
export interface AdminApiAdminPartnerCreateRequest {
    /**
     * 
     * @type {PartnerCreatePayload}
     * @memberof AdminApiAdminPartnerCreate
     */
    readonly partnerCreatePayload?: PartnerCreatePayload
}

/**
 * Request parameters for adminPartnerDelete operation in AdminApi.
 * @export
 * @interface AdminApiAdminPartnerDeleteRequest
 */
export interface AdminApiAdminPartnerDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminPartnerDelete
     */
    readonly partnerId: string
}

/**
 * Request parameters for adminPartnerRetrieve operation in AdminApi.
 * @export
 * @interface AdminApiAdminPartnerRetrieveRequest
 */
export interface AdminApiAdminPartnerRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminPartnerRetrieve
     */
    readonly partnerId: string
}

/**
 * Request parameters for adminProductCreate operation in AdminApi.
 * @export
 * @interface AdminApiAdminProductCreateRequest
 */
export interface AdminApiAdminProductCreateRequest {
    /**
     * 
     * @type {ProductCreateRequest}
     * @memberof AdminApiAdminProductCreate
     */
    readonly productCreateRequest?: ProductCreateRequest
}

/**
 * Request parameters for adminProductDelete operation in AdminApi.
 * @export
 * @interface AdminApiAdminProductDeleteRequest
 */
export interface AdminApiAdminProductDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminProductDelete
     */
    readonly productId: string
}

/**
 * Request parameters for adminVersionList operation in AdminApi.
 * @export
 * @interface AdminApiAdminVersionListRequest
 */
export interface AdminApiAdminVersionListRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminVersionList
     */
    readonly offer: string
}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary admin offer archive
     * @param {AdminApiAdminOfferArchiveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminOfferArchive(requestParameters: AdminApiAdminOfferArchiveRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminOfferArchive(requestParameters.offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin offer create
     * @param {AdminApiAdminOfferCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminOfferCreate(requestParameters: AdminApiAdminOfferCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminOfferCreate(requestParameters.adminOfferCreatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin offer list
     * @param {AdminApiAdminOfferListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminOfferList(requestParameters: AdminApiAdminOfferListRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminOfferList(requestParameters.limit, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin offer publish
     * @param {AdminApiAdminOfferPublishRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminOfferPublish(requestParameters: AdminApiAdminOfferPublishRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminOfferPublish(requestParameters.offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin offer retrieve
     * @param {AdminApiAdminOfferRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminOfferRetrieve(requestParameters: AdminApiAdminOfferRetrieveRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminOfferRetrieve(requestParameters.offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin partner create
     * @param {AdminApiAdminPartnerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPartnerCreate(requestParameters: AdminApiAdminPartnerCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPartnerCreate(requestParameters.partnerCreatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin partner delete
     * @param {AdminApiAdminPartnerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPartnerDelete(requestParameters: AdminApiAdminPartnerDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPartnerDelete(requestParameters.partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin partner list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPartnerList(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPartnerList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin partner retrieve
     * @param {AdminApiAdminPartnerRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPartnerRetrieve(requestParameters: AdminApiAdminPartnerRetrieveRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPartnerRetrieve(requestParameters.partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin product create
     * @param {AdminApiAdminProductCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminProductCreate(requestParameters: AdminApiAdminProductCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminProductCreate(requestParameters.productCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin product delete
     * @param {AdminApiAdminProductDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminProductDelete(requestParameters: AdminApiAdminProductDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminProductDelete(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin product list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminProductList(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminProductList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin version list
     * @param {AdminApiAdminVersionListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminVersionList(requestParameters: AdminApiAdminVersionListRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminVersionList(requestParameters.offer, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary eligibility create
         * @param {EligibilityCreateRequest} [eligibilityCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityCreate: async (eligibilityCreateRequest?: EligibilityCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/eligibility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eligibilityCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary offer list
         * @param {number | null} [limit] 
         * @param {OfferStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerList: async (limit?: number | null, status?: OfferStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary offer retrieve
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerRetrieve: async (offerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerRetrieve', 'offerId', offerId)
            const localVarPath = `/offers/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partner list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partner retrieve
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerRetrieve: async (partnerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('partnerRetrieve', 'partnerId', partnerId)
            const localVarPath = `/partners/{partnerId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary product list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary promotion list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary eligibility create
         * @param {EligibilityCreateRequest} [eligibilityCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eligibilityCreate(eligibilityCreateRequest?: EligibilityCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Eligibility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eligibilityCreate(eligibilityCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.eligibilityCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary offer list
         * @param {number | null} [limit] 
         * @param {OfferStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerList(limit?: number | null, status?: OfferStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerList(limit, status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.offerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary offer retrieve
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerRetrieve(offerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerRetrieve(offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.offerRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary partner list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partnerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary partner retrieve
         * @param {string} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerRetrieve(partnerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerRetrieve(partnerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partnerRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary product list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary promotion list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.promotionList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary eligibility create
         * @param {DefaultApiEligibilityCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eligibilityCreate(requestParameters: DefaultApiEligibilityCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Eligibility> {
            return localVarFp.eligibilityCreate(requestParameters.eligibilityCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary offer list
         * @param {DefaultApiOfferListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerList(requestParameters: DefaultApiOfferListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OfferList200Response> {
            return localVarFp.offerList(requestParameters.limit, requestParameters.status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary offer retrieve
         * @param {DefaultApiOfferRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerRetrieve(requestParameters: DefaultApiOfferRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<Offer> {
            return localVarFp.offerRetrieve(requestParameters.offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partner list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerList(options?: RawAxiosRequestConfig): AxiosPromise<PartnerList200Response> {
            return localVarFp.partnerList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partner retrieve
         * @param {DefaultApiPartnerRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerRetrieve(requestParameters: DefaultApiPartnerRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<Partner> {
            return localVarFp.partnerRetrieve(requestParameters.partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary product list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productList(options?: RawAxiosRequestConfig): AxiosPromise<ProductList200Response> {
            return localVarFp.productList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary promotion list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionList(options?: RawAxiosRequestConfig): AxiosPromise<PromotionList200Response> {
            return localVarFp.promotionList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for eligibilityCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiEligibilityCreateRequest
 */
export interface DefaultApiEligibilityCreateRequest {
    /**
     * 
     * @type {EligibilityCreateRequest}
     * @memberof DefaultApiEligibilityCreate
     */
    readonly eligibilityCreateRequest?: EligibilityCreateRequest
}

/**
 * Request parameters for offerList operation in DefaultApi.
 * @export
 * @interface DefaultApiOfferListRequest
 */
export interface DefaultApiOfferListRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiOfferList
     */
    readonly limit?: number | null

    /**
     * 
     * @type {OfferStatus}
     * @memberof DefaultApiOfferList
     */
    readonly status?: OfferStatus
}

/**
 * Request parameters for offerRetrieve operation in DefaultApi.
 * @export
 * @interface DefaultApiOfferRetrieveRequest
 */
export interface DefaultApiOfferRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiOfferRetrieve
     */
    readonly offerId: string
}

/**
 * Request parameters for partnerRetrieve operation in DefaultApi.
 * @export
 * @interface DefaultApiPartnerRetrieveRequest
 */
export interface DefaultApiPartnerRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPartnerRetrieve
     */
    readonly partnerId: string
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary eligibility create
     * @param {DefaultApiEligibilityCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public eligibilityCreate(requestParameters: DefaultApiEligibilityCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).eligibilityCreate(requestParameters.eligibilityCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary offer list
     * @param {DefaultApiOfferListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public offerList(requestParameters: DefaultApiOfferListRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).offerList(requestParameters.limit, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary offer retrieve
     * @param {DefaultApiOfferRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public offerRetrieve(requestParameters: DefaultApiOfferRetrieveRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).offerRetrieve(requestParameters.offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partner list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partnerList(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partnerList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partner retrieve
     * @param {DefaultApiPartnerRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partnerRetrieve(requestParameters: DefaultApiPartnerRetrieveRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partnerRetrieve(requestParameters.partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary product list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productList(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary promotion list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public promotionList(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).promotionList(options).then((request) => request(this.axios, this.basePath));
    }
}



