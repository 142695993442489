import React from 'react';
import { BeatLoader } from 'react-spinners';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    BoxProps,
    Button,
    ButtonGroup,
    Flex,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Progress,
    Radio,
    SimpleGrid,
    Stack,
    StackDivider,
    SystemProps,
    Tag,
    Text,
    Textarea,
    Tooltip,
    useBreakpointValue,
    useToken,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import { ChevronRightIcon, LockIcon } from '@chakra-ui/icons';
import {
    AiOutlineCheck,
    AiOutlineCheckSquare,
    AiOutlineCreditCard,
    AiOutlineInfoCircle,
    AiOutlineLock,
    AiOutlineQuestionCircle,
    AiOutlineSend,
    AiOutlineTrophy,
} from 'react-icons/ai';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { MdOutlineInsertLink, MdSubdirectoryArrowRight } from 'react-icons/md';
import { Icons } from '../../../../../../config';
import { Graphics } from '../../../../../config/svg';
import { assertNever } from '../../../../../util';
import { isKeywordLink, isKeywordText } from '../../../../../view/common';
import { RewardViewBaseConfig } from '../../../base';
import { OfferDetailViewProps } from './offerDetailProps';
import { rewardStyles } from '../../../constant';
import { HiArrowTurnDownRight } from 'react-icons/hi2';
import { OfferItemRewardProps, OfferItemTaskProps } from '../../../fragment';
import { GoChecklist } from 'react-icons/go';
import {
    TbHexagonNumber1,
    TbHexagonNumber2,
    TbHexagonNumber3,
    TbHexagonNumber4,
} from 'react-icons/tb';
import { FiCheckCircle } from 'react-icons/fi';
import { HiOutlineDocumentText } from 'react-icons/hi';

const NumberIcons = [
    TbHexagonNumber1,
    TbHexagonNumber2,
    TbHexagonNumber3,
    TbHexagonNumber4,
];

export function createOfferDetailView(
    config: RewardViewBaseConfig
): React.FC<{ children?: React.ReactNode | undefined } & OfferDetailViewProps> {
    const {
        UI: {
            Application: { Link, Anchor },
        },
        Layout,
    } = config;

    const TaskItem: React.FC<OfferItemTaskProps> = (props) => (
        <HStack w="full" justify="space-between" p={6}>
            <HStack spacing={4}>
                <Box p={3} color="whiteAlpha.600" bg="whiteAlpha.200" borderRadius="md">
                    <Icon
                        w={5}
                        h={5}
                        as={NumberIcons[props.index % NumberIcons.length]}
                    />
                </Box>
                <VStack align="start" spacing={0}>
                    <HStack spacing={3}>
                        <HStack>
                            <Text fontWeight="semibold">{props.task.label}</Text>
                            <Tooltip
                                {...props.task.tooltip}
                                placement="top"
                                p={2}
                                bg="gray.50"
                                color="gray.900"
                                hasArrow={true}
                                shouldWrapChildren={true}
                            >
                                <Icon as={AiOutlineInfoCircle} />
                            </Tooltip>
                        </HStack>
                    </HStack>
                    {props.task.object && (
                        <HStack spacing={2} fontWeight="medium">
                            <Icon
                                color="whiteAlpha.300"
                                fontSize="lg"
                                as={MdSubdirectoryArrowRight}
                            />
                            <Popover placement="top" trigger="hover">
                                <PopoverTrigger>
                                    <HStack
                                        cursor="pointer"
                                        _hover={{
                                            textDecor: 'underline',
                                        }}
                                        spacing={2}
                                        color="whiteAlpha.600"
                                    >
                                        {/* <Icon as={Icons.Reports.Study} /> */}
                                        <Text>{props.task.object.label}</Text>
                                    </HStack>
                                </PopoverTrigger>
                                <PopoverContent
                                    bg="black"
                                    color="whiteAlpha.900"
                                    overflow="hidden"
                                    maxW="24rem"
                                    w="full"
                                    _focus={{ outline: 'none' }}
                                >
                                    <PopoverBody p={0} bg="whiteAlpha.100">
                                        <VStack
                                            align="start"
                                            w="full"
                                            p={5}
                                            spacing={4}
                                            divider={
                                                <StackDivider
                                                    borderWidth={1}
                                                    borderColor="whiteAlpha.100"
                                                />
                                            }
                                        >
                                            <VStack align="start" w="full" spacing={4}>
                                                <HStack
                                                    w="full"
                                                    justify="space-between"
                                                    spacing={3}
                                                >
                                                    <HStack spacing={3} flex={1}>
                                                        <Box
                                                            p={2.5}
                                                            bg="whiteAlpha.100"
                                                            borderRadius="md"
                                                        >
                                                            <Icon
                                                                w={5}
                                                                h={5}
                                                                color="whiteAlpha.600"
                                                                as={Icons.Reports.Study}
                                                            />
                                                        </Box>
                                                        <VStack
                                                            align="start"
                                                            w="full"
                                                            spacing={0}
                                                        >
                                                            <Text noOfLines={2}>
                                                                {props.task.object.label}
                                                            </Text>
                                                        </VStack>
                                                    </HStack>
                                                    <Text
                                                        // textTransform="capitalize"
                                                        color="whiteAlpha.500"
                                                        textTransform="uppercase"
                                                        fontWeight="semibold"
                                                        fontSize="xs"
                                                        letterSpacing="wide"
                                                    >
                                                        {props.task.object.type}
                                                    </Text>
                                                </HStack>
                                                {props.task.object.description && (
                                                    <Text
                                                        color="whiteAlpha.700"
                                                        fontSize="sm"
                                                    >
                                                        {props.task.object.description}
                                                    </Text>
                                                )}
                                            </VStack>
                                            {props.task.action.kind === 'pending' &&
                                                props.task.action.link && (
                                                    <HStack w="full" justify="end">
                                                        <Link {...props.task.action.link}>
                                                            <Button
                                                                variant="link"
                                                                size="sm"
                                                                textTransform="uppercase"
                                                                color="blue.300"
                                                            >
                                                                {props.task.action.label}
                                                            </Button>
                                                        </Link>
                                                    </HStack>
                                                )}
                                        </VStack>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </HStack>
                    )}
                </VStack>
            </HStack>
            <HStack flexShrink={0}>
                {props.task.action.kind === 'pending' && props.task.action.link && (
                    <Link {...props.task.action.link}>
                        <Button variant="link" textTransform="uppercase" color="blue.300">
                            {props.task.action.label}
                        </Button>
                    </Link>
                )}
                {props.task.action.kind !== 'pending' && (
                    <Tooltip
                        placement="top"
                        p={2}
                        bg="gray.50"
                        color="gray.900"
                        hasArrow={true}
                        shouldWrapChildren={true}
                        label={`Et ex esse ad nisi eu nulla aute mollit nulla voluptate`}
                    >
                        <Icon
                            fontSize="xl"
                            color={`${props.task.action.colorScheme}.300`}
                            as={FiCheckCircle}
                        />
                    </Tooltip>
                )}
            </HStack>
        </HStack>
    );

    const RewardItem: React.FC<OfferItemRewardProps> = (props) => (
        <HStack
            aria-checked={props.radio.isChecked}
            aria-disabled={true}
            w="full"
            p={6}
            justify="space-between"
            cursor="pointer"
            _checked={{ borderColor: 'blue.300' }}
            _disabled={{ opacity: 0.5, cursor: 'default' }}
        >
            <HStack spacing={4}>
                <Box p={3} color="whiteAlpha.600" bg="whiteAlpha.200" borderRadius="md">
                    <Icon w={5} h={5} as={AiOutlineCreditCard} />
                </Box>
                <HStack spacing={2}>
                    <Text fontWeight="semibold">{props.product.name}</Text>
                    {!props.status.tooltip.isDisabled && (
                        <Tooltip
                            {...props.status.tooltip}
                            placement="top"
                            p={2}
                            bg="gray.50"
                            color="gray.900"
                            hasArrow={true}
                            shouldWrapChildren={true}
                        >
                            <Icon as={AiOutlineInfoCircle} />
                        </Tooltip>
                    )}
                </HStack>
            </HStack>
            {/* <Radio {...props.radio} /> */}
            <Tooltip
                placement="top"
                p={2}
                bg="gray.50"
                color="gray.900"
                hasArrow={true}
                shouldWrapChildren={true}
                label={props.status.description}
            >
                <Icon fontSize="lg" as={LockIcon} />
            </Tooltip>
        </HStack>
    );

    return (props) => {
        const isHorizontalLayout =
            useBreakpointValue({ base: false, lg: true }, { ssr: false }) ?? false;

        const colorSpinner = useToken('colors', 'whiteAlpha.400');
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');
        return (
            <HStack w="full" h="full" justify="center" py={12}>
                <VStack
                    maxW={rewardStyles.content.maxW}
                    align="start"
                    w="full"
                    h="full"
                    spacing={12}
                >
                    <Grid w="full" h="full" templateColumns="3fr">
                        <GridItem>
                            <VStack align="start" w="full" spacing={12}>
                                <VStack align="start" w="full" spacing={3}>
                                    <Link {...props.discover.link}>
                                        <HStack fontSize="md">
                                            <Text fontWeight="semibold" color="blue.300">
                                                Rewards
                                            </Text>
                                            <Icon
                                                as={ChevronRightIcon}
                                                color="whiteAlpha.500"
                                            />
                                        </HStack>
                                    </Link>
                                    <HStack
                                        w="full"
                                        justify="space-between"
                                        align="start"
                                    >
                                        <HStack spacing={6}>
                                            <Box
                                                bg="whiteAlpha.100"
                                                borderRadius="lg"
                                                flexShrink={0}
                                                p={4}
                                                userSelect="none"
                                            >
                                                <Icon
                                                    color="whiteAlpha.600"
                                                    w={5}
                                                    h={5}
                                                    as={Icons.Rewards.Offer}
                                                />
                                            </Box>
                                            <VStack align="start" spacing={1}>
                                                <Heading
                                                    fontWeight="semibold"
                                                    fontSize="2xl"
                                                >
                                                    {props.item.label}
                                                </Heading>
                                            </VStack>
                                        </HStack>
                                        <HStack>
                                            <Tooltip
                                                {...props.root.status.getTooltipProps()}
                                                placement="top"
                                                p={2}
                                                bg="gray.50"
                                                color="gray.900"
                                                hasArrow={true}
                                                shouldWrapChildren={true}
                                            >
                                                <Tag
                                                    {...props.root.status.getTagProps()}
                                                    size="lg"
                                                    userSelect="none"
                                                >
                                                    {props.root.status.label}
                                                </Tag>
                                            </Tooltip>
                                        </HStack>
                                    </HStack>
                                    <Text
                                        color="whiteAlpha.700"
                                        maxW="32rem"
                                        noOfLines={2}
                                        fontSize="lg"
                                    >
                                        {props.item.caption}
                                    </Text>
                                </VStack>
                                <VStack align="start" w="full" spacing={12}>
                                    <Alert status="warning" w="full">
                                        <AlertIcon />
                                        <AlertTitle w="full">
                                            <HStack w="full" justify="space-between">
                                                <Text>
                                                    Complete requirements to unlock
                                                    rewards
                                                </Text>
                                                <Text
                                                    cursor="pointer"
                                                    _hover={{ textDecor: 'underline' }}
                                                >
                                                    Learn more
                                                </Text>
                                            </HStack>
                                        </AlertTitle>
                                    </Alert>
                                    <Grid
                                        w="full"
                                        templateColumns={
                                            isHorizontalLayout
                                                ? '1fr min-content 1fr'
                                                : '1fr'
                                        }
                                        gap={8}
                                    >
                                        {/* Requirements */}
                                        <GridItem>
                                            <VStack w="full" align="start" spacing={6}>
                                                <HStack w="full" justify="space-between">
                                                    <HStack spacing={3} align="center">
                                                        <Icon
                                                            fontSize="xl"
                                                            color="whiteAlpha.800"
                                                            as={GoChecklist}
                                                        />
                                                        <Heading
                                                            fontWeight="semibold"
                                                            fontSize="xl"
                                                            color="whiteAlpha.800"
                                                        >
                                                            Requirements
                                                        </Heading>
                                                    </HStack>
                                                    <HStack spacing={3} userSelect="none">
                                                        <Box
                                                            flexShrink={0}
                                                            w={2.5}
                                                            h={2.5}
                                                            bg={`${props.task.summary.colorScheme}.300`}
                                                            borderRadius="full"
                                                        />
                                                        <Text
                                                            fontSize="sm"
                                                            fontWeight="bold"
                                                            textTransform="uppercase"
                                                            letterSpacing="wide"
                                                            color="whiteAlpha.500"
                                                        >
                                                            {props.task.summary.label}
                                                        </Text>
                                                    </HStack>
                                                </HStack>
                                                <VStack
                                                    align="start"
                                                    w="full"
                                                    spacing={4}
                                                >
                                                    <HStack w="full" spacing={6}>
                                                        <Progress
                                                            {...props.task.progress}
                                                            w="full"
                                                            colorScheme="orange"
                                                            hasStripe={true}
                                                            size="md"
                                                            borderRadius="md"
                                                        />
                                                    </HStack>
                                                    <VStack
                                                        align="start"
                                                        w="full"
                                                        borderWidth={2}
                                                        borderColor="whiteAlpha.200"
                                                        borderRadius="lg"
                                                        spacing={0}
                                                        divider={
                                                            <StackDivider
                                                                borderWidth={1}
                                                                borderColor="whiteAlpha.100"
                                                            />
                                                        }
                                                    >
                                                        {props.item.tasks.map(
                                                            (task, index) => (
                                                                <TaskItem
                                                                    key={task.id}
                                                                    {...task}
                                                                />
                                                            )
                                                        )}
                                                    </VStack>
                                                    <HStack
                                                        color="whiteAlpha.500"
                                                        align="start"
                                                    >
                                                        <Text>
                                                            Complete at least one
                                                            requirement
                                                        </Text>
                                                    </HStack>
                                                </VStack>
                                            </VStack>
                                        </GridItem>
                                        {/* Divider */}
                                        {isHorizontalLayout && (
                                            <GridItem>
                                                <VStack h="full" w="full" spacing={4}>
                                                    <Box
                                                        h="full"
                                                        borderLeftWidth={2.5}
                                                        borderColor="whiteAlpha.200"
                                                    />
                                                    <Icon
                                                        color="whiteAlpha.200"
                                                        fontSize="4xl"
                                                        as={BsArrowRightShort}
                                                    />
                                                    <Box
                                                        h="full"
                                                        borderLeftWidth={2.5}
                                                        borderColor="whiteAlpha.200"
                                                    />
                                                </VStack>
                                            </GridItem>
                                        )}
                                        {/* Rewards */}
                                        <GridItem>
                                            <VStack w="full" align="start" spacing={6}>
                                                <HStack
                                                    w="full"
                                                    justify="space-between"
                                                    align="start"
                                                >
                                                    <HStack spacing={3}>
                                                        <Icon
                                                            fontSize="xl"
                                                            color="whiteAlpha.800"
                                                            as={AiOutlineTrophy}
                                                        />
                                                        <Heading
                                                            fontWeight="semibold"
                                                            fontSize="xl"
                                                            color="whiteAlpha.800"
                                                        >
                                                            Rewards
                                                        </Heading>
                                                    </HStack>
                                                    <HStack>
                                                        <Tooltip
                                                            placement="top"
                                                            p={2}
                                                            bg="gray.50"
                                                            color="gray.900"
                                                            hasArrow={true}
                                                            shouldWrapChildren={true}
                                                            label={`Requirements for this reward are not yet fulfilled`}
                                                        >
                                                            <Button
                                                                colorScheme="green"
                                                                isDisabled={true}
                                                                leftIcon={
                                                                    <Icon as={LockIcon} />
                                                                }
                                                            >
                                                                Claim reward
                                                            </Button>
                                                        </Tooltip>
                                                    </HStack>
                                                </HStack>
                                                <VStack
                                                    align="start"
                                                    w="full"
                                                    spacing={4}
                                                >
                                                    <VStack
                                                        align="start"
                                                        w="full"
                                                        // bg="whiteAlpha.100"
                                                        borderWidth={2}
                                                        borderColor="whiteAlpha.200"
                                                        borderRadius="lg"
                                                        spacing={0}
                                                        divider={
                                                            <StackDivider
                                                                borderWidth={1}
                                                                borderColor="whiteAlpha.100"
                                                            />
                                                        }
                                                    >
                                                        {props.item.rewards?.map(
                                                            (item) => (
                                                                <RewardItem
                                                                    key={item.id}
                                                                    {...item}
                                                                />
                                                            )
                                                        )}
                                                    </VStack>
                                                    <HStack
                                                        w="full"
                                                        justify="space-between"
                                                        align="start"
                                                        spacing={12}
                                                    >
                                                        <HStack
                                                            color="whiteAlpha.500"
                                                            align="start"
                                                        >
                                                            <Text>
                                                                Select one of the
                                                                specified rewards
                                                            </Text>
                                                        </HStack>
                                                    </HStack>
                                                </VStack>
                                            </VStack>
                                        </GridItem>
                                    </Grid>
                                    {/* Description */}
                                    <VStack
                                        w="full"
                                        align="start"
                                        spacing={6}
                                        divider={
                                            <StackDivider
                                                borderColor="whiteAlpha.200"
                                                borderWidth={1}
                                            />
                                        }
                                    >
                                        <HStack>
                                            <Heading
                                                fontWeight="semibold"
                                                fontSize="xl"
                                                color="whiteAlpha.800"
                                            >
                                                Description
                                            </Heading>
                                        </HStack>
                                        <VStack
                                            align="start"
                                            w="full"
                                            maxW="42rem"
                                            spacing={6}
                                        >
                                            <Text color="whiteAlpha.700">
                                                {props.item.description}
                                            </Text>
                                            <VStack
                                                align="start"
                                                spacing={3}
                                                userSelect="none"
                                            >
                                                <Text
                                                    fontWeight="medium"
                                                    color="blue.400"
                                                >
                                                    {props.item.partner.label}
                                                </Text>
                                                <VStack
                                                    align="start"
                                                    spacing={2}
                                                    color="whiteAlpha.600"
                                                    fontWeight="medium"
                                                >
                                                    <Anchor
                                                        {...props.item.partner.website
                                                            .anchor}
                                                    >
                                                        <HStack spacing={3}>
                                                            <Icon
                                                                as={MdOutlineInsertLink}
                                                            />
                                                            <Text>Website</Text>
                                                        </HStack>
                                                    </Anchor>
                                                    <Anchor
                                                        {...props.item.partner.website
                                                            .anchor}
                                                    >
                                                        <HStack spacing={3}>
                                                            <Icon
                                                                as={
                                                                    AiOutlineQuestionCircle
                                                                }
                                                            />
                                                            <Text>Support</Text>
                                                        </HStack>
                                                    </Anchor>
                                                </VStack>
                                            </VStack>
                                        </VStack>
                                    </VStack>
                                    {/* Details */}
                                    <VStack
                                        w="full"
                                        align="start"
                                        spacing={6}
                                        divider={
                                            <StackDivider
                                                borderColor="whiteAlpha.200"
                                                borderWidth={1}
                                            />
                                        }
                                    >
                                        <HStack>
                                            <Heading
                                                fontWeight="semibold"
                                                fontSize="xl"
                                                color="whiteAlpha.800"
                                            >
                                                Details
                                            </Heading>
                                        </HStack>
                                        <SimpleGrid
                                            w="full"
                                            columns={{ base: 1, md: 2 }}
                                            gap={6}
                                        >
                                            {/* Info */}
                                            {props.item.keywords.items
                                                .filter(isKeywordText)
                                                .map((item) => (
                                                    <VStack
                                                        key={item.id}
                                                        spacing={1}
                                                        align="start"
                                                        w="full"
                                                    >
                                                        <Text
                                                            color="whiteAlpha.600"
                                                            fontWeight="bold"
                                                            fontSize="xs"
                                                            letterSpacing="wide"
                                                            textTransform="uppercase"
                                                        >
                                                            {item.label}
                                                        </Text>
                                                        <HStack
                                                            spacing={3}
                                                            color="whiteAlpha.800"
                                                            fontSize="md"
                                                            fontWeight="medium"
                                                            align="center"
                                                        >
                                                            {item.image && (
                                                                <Box>
                                                                    <Image
                                                                        w={16}
                                                                        {...item.image}
                                                                    />
                                                                </Box>
                                                            )}
                                                            {item.value && (
                                                                <HStack spacing={2}>
                                                                    <Text>
                                                                        {item.value}
                                                                    </Text>
                                                                    {item.tooltip && (
                                                                        <Tooltip
                                                                            placement="top"
                                                                            p={2}
                                                                            bg="gray.50"
                                                                            color="gray.900"
                                                                            hasArrow={
                                                                                true
                                                                            }
                                                                            shouldWrapChildren={
                                                                                true
                                                                            }
                                                                            {...item.tooltip}
                                                                        >
                                                                            <Icon
                                                                                as={
                                                                                    AiOutlineInfoCircle
                                                                                }
                                                                            />
                                                                        </Tooltip>
                                                                    )}
                                                                </HStack>
                                                            )}
                                                        </HStack>
                                                    </VStack>
                                                ))}
                                            {/* Links */}
                                            {props.item.keywords.items
                                                .filter(isKeywordLink)
                                                .map((item) => (
                                                    <VStack
                                                        key={item.id}
                                                        align="start"
                                                        // justify="end"
                                                        h="full"
                                                    >
                                                        <Link {...item.link}>
                                                            <HStack
                                                                spacing={2}
                                                                fontSize="md"
                                                                fontWeight="medium"
                                                                color="blue.300"
                                                                _hover={{
                                                                    color: 'blue.400',
                                                                    textDecor:
                                                                        'underline',
                                                                }}
                                                            >
                                                                {item.Icon && (
                                                                    <Icon
                                                                        fontSize="lg"
                                                                        as={item.Icon}
                                                                    />
                                                                )}
                                                                {item.label && (
                                                                    <Text>
                                                                        {item.label}
                                                                    </Text>
                                                                )}
                                                            </HStack>
                                                        </Link>
                                                    </VStack>
                                                ))}
                                            <Link {...props.terms.link}>
                                                <HStack
                                                    spacing={2}
                                                    fontSize="md"
                                                    fontWeight="medium"
                                                    color="blue.300"
                                                    _hover={{
                                                        color: 'blue.400',
                                                        textDecor: 'underline',
                                                    }}
                                                >
                                                    <Icon
                                                        fontSize="lg"
                                                        as={HiOutlineDocumentText}
                                                    />
                                                    <Text>{props.terms.label}</Text>
                                                </HStack>
                                            </Link>
                                        </SimpleGrid>
                                    </VStack>
                                    {/* Related */}
                                    {props.related && (
                                        <VStack w="full" align="start" spacing={6}>
                                            <HStack>
                                                <Heading
                                                    fontWeight="semibold"
                                                    fontSize="xl"
                                                    color="whiteAlpha.800"
                                                >
                                                    Other offers
                                                </Heading>
                                            </HStack>
                                            <SimpleGrid
                                                w="full"
                                                columns={{ base: 1, md: 2, '2xl': 3 }}
                                                gap={6}
                                            >
                                                {props.related.map((item) => (
                                                    <VStack
                                                        key={item.id}
                                                        align="start"
                                                        w="full"
                                                        p={6}
                                                        bg="whiteAlpha.100"
                                                        borderRadius="xl"
                                                        spacing={4}
                                                    >
                                                        <HStack spacing={4}>
                                                            <Box
                                                                bg="whiteAlpha.100"
                                                                borderRadius="lg"
                                                                flexShrink={0}
                                                                p={2}
                                                                userSelect="none"
                                                            >
                                                                <Image
                                                                    w={8}
                                                                    h={8}
                                                                    fit="contain"
                                                                    {...item.image}
                                                                />
                                                            </Box>
                                                            <VStack
                                                                align="start"
                                                                w="full"
                                                                spacing={1}
                                                            >
                                                                <Text
                                                                    fontWeight="semibold"
                                                                    color="whiteAlpha.900"
                                                                    fontSize="lg"
                                                                >
                                                                    {item.label}
                                                                </Text>
                                                                <Wrap
                                                                    shouldWrapChildren={
                                                                        true
                                                                    }
                                                                    userSelect="none"
                                                                >
                                                                    {item.tags.map(
                                                                        (tag) => (
                                                                            <Tag
                                                                                key={
                                                                                    tag.id
                                                                                }
                                                                                colorScheme="blue"
                                                                                variant="subtle"
                                                                            >
                                                                                {
                                                                                    tag.label
                                                                                }
                                                                            </Tag>
                                                                        )
                                                                    )}
                                                                </Wrap>
                                                            </VStack>
                                                        </HStack>
                                                        <Text color="whiteAlpha.700">
                                                            {item.caption}
                                                        </Text>
                                                        <HStack
                                                            w="full"
                                                            justify="space-between"
                                                        >
                                                            <Link
                                                                style={{ width: '100%' }}
                                                                {...item.link}
                                                            >
                                                                <Button
                                                                    w="full"
                                                                    size="sm"
                                                                    rightIcon={
                                                                        <Icon
                                                                            fontSize="xl"
                                                                            as={
                                                                                BsArrowRightShort
                                                                            }
                                                                        />
                                                                    }
                                                                    bg="whiteAlpha.50"
                                                                    _hover={{
                                                                        bg: 'whiteAlpha.100',
                                                                    }}
                                                                >
                                                                    Claim reward
                                                                </Button>
                                                            </Link>
                                                        </HStack>
                                                    </VStack>
                                                ))}
                                            </SimpleGrid>
                                        </VStack>
                                    )}
                                </VStack>
                            </VStack>
                        </GridItem>
                    </Grid>
                </VStack>
            </HStack>
        );
    };
}
