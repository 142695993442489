import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDisclosure, useToken } from '@chakra-ui/react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AiOutlineHome, AiOutlineLogout } from 'react-icons/ai';
import { PageIds } from '../../../../config';
import { ContributorShellUrlParamSchema } from '../../contributorShellSchema';
import { ContributorShellContainerBaseConfig } from '../../base';
import { ContributorRootContainerProps } from './contributorRootProps';
import { ContributorErrorContainerProps } from '../error';
import {
    ContributorRootController,
    ContributorRootLoader,
} from './contributorRootlInterface';

export function createContributorRootContainer(
    config: ContributorShellContainerBaseConfig & {
        Container: {
            Error: React.FC<ContributorErrorContainerProps>;
        };
    },
    loader: ContributorRootLoader,
    controller: ContributorRootController
): React.FC<ContributorRootContainerProps> {
    const {
        UI: {
            Application: { createErrorBoundary },
        },
        Container: { Error: ErrorContainer },
        Theme: {
            Desktop: { Shell: DesktopView, Error: DesktopErrorView },
            Mobile: { Shell: MobileView, Error: MobileErrorView },
        },
        context: { useContext },
        kernel: {
            infra: {
                overlay: { Provider: OverlayProvider },
                optionParser: { useOptions },
            },
        },
    } = config;

    const ErrorBoundary = createErrorBoundary({
        errorClass: Error,
    });

    return ({ children, ...containerProps }) => {
        const location = useLocation();
        const navigate = useNavigate();
        const overlayRef = useRef<HTMLDivElement>(null);
        const backgroundColor = useToken('colors', 'whiteAlpha.100');
        const [searchParams, setSearchParams] = useSearchParams();

        const context = useContext();

        const options = useOptions(ContributorShellUrlParamSchema);
        const data = loader.useLoad(context, containerProps);

        const props = controller.useProps(context, data, {
            location,
            navigate,
            home: {
                link: {
                    to: config.basePath,
                },
            },
            status: null,
            account: {
                disclosure: useDisclosure(),
                actions: [],
                action: {
                    logout: {
                        id: 'logout',
                        name: 'Logout',
                        kind: 'custom',
                        Icon: AiOutlineLogout,
                        onPerform() {
                            return config.kernel.infra.authenticator.logout(context);
                        },
                    },
                },
            },
            navigation: {
                navigate,
                location,
                disclosure: useDisclosure({
                    isOpen: options.overlay === 'workspace',
                    onOpen() {
                        searchParams.set('overlay', 'workspace');
                        setSearchParams(searchParams);
                    },
                    onClose() {
                        searchParams.delete('overlay');
                        setSearchParams(searchParams);
                    },
                }),
                items: [
                    {
                        id: PageIds.REWARD_OFFER_OVERVIEW,
                        label: 'Rewards',
                        Icon: AiOutlineHome,
                        path: `/contributor/rewards/offers`,
                    },
                ],
            },
            footer: {
                copyright: `© Copyright ${new Date().getFullYear()} | Varos. All rights reserved.`,
                groups: [
                    {
                        label: 'Varos',
                        links: [
                            {
                                label: 'Home',
                                href: 'https://varos.com',
                            },
                            {
                                label: 'How Varos works',
                                href: 'https://www.varos.com/how-varos-works',
                            },
                            {
                                label: 'Case studies',
                                href: 'https://www.varos.com/case-studies',
                            },
                            {
                                label: 'Blog',
                                href: `https://www.varos.com/blog`,
                            },
                            {
                                label: 'Videos',
                                href: `https://www.varos.com/videos`,
                            },
                        ],
                    },
                    {
                        label: 'Social',
                        links: [
                            {
                                label: 'LinkedIn',
                                href: 'https://www.linkedin.com/company/varos-io',
                            },
                        ],
                    },
                    {
                        label: 'Company',
                        links: [
                            {
                                label: 'About us',
                                href: 'https://www.varos.com/about',
                            },
                            {
                                label: 'Jobs',
                                href: 'https://www.ycombinator.com/companies/varos',
                            },
                            {
                                label: 'Privacy Policy',
                                href: 'https://www.varos.com/p/privacy-policy',
                            },
                            {
                                label: 'Terms of Use',
                                href: 'https://www.varos.com/p/terms-of-use',
                            },
                            {
                                label: 'Become a partner',
                                href: 'https://varos.com',
                            },
                        ],
                    },
                ],
            },
            scroll: {
                ref: useRef(null),
            },
        });

        if (props.browser.mode === 'mobile') {
            return (
                <OverlayProvider overlayRef={overlayRef}>
                    <Helmet>
                        {/* set correct theme background for mobiel devices */}
                        <meta name="theme-color" content={backgroundColor} />
                    </Helmet>
                    <MobileView {...props}>
                        <ErrorBoundary
                            key={props.error.hash}
                            fallback={(info) => (
                                <ErrorContainer as={MobileErrorView} info={info} />
                            )}
                        >
                            {children}
                        </ErrorBoundary>
                    </MobileView>
                </OverlayProvider>
            );
        }
        return (
            <DesktopView {...props}>
                <ErrorBoundary
                    key={props.error.hash}
                    fallback={(info) => (
                        <ErrorContainer as={DesktopErrorView} info={info} />
                    )}
                >
                    {children}
                </ErrorBoundary>
            </DesktopView>
        );
    };
}
