import axios from 'axios';
import { AdminApi, Configuration, DefaultApi } from '../generated';
import { RewardSdk, RewardSdkEnhancer } from './sdkInterface';
import { SdkConfig, SdkInit } from './sdkConfig';
import { convertDates } from './sdkHelper';

export function createContributorSdk(init: SdkInit, enhancer?: RewardSdkEnhancer) {
    return _create(init, enhancer);
}

function _create(config: SdkConfig, enhancer?: RewardSdkEnhancer): RewardSdk {
    if (enhancer) {
        return enhancer(_create)(config);
    }
    const apiConfig = new Configuration({
        basePath: config.baseUrl,
    });
    const client = config.axios ?? axios.create();

    // client.interceptors.request.use(
    //     (request) => {
    //         return request;
    //     },

    // );

    // TODO fix openapi generator to properly deserialize dates
    client.interceptors.response.use(
        (response) => {
            if (response.data && typeof response.data === 'object') {
                response.data = convertDates(response.data);
            }
            return response;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return {
        contributor: new DefaultApi(apiConfig, config.baseUrl, client),
        admin: new AdminApi(apiConfig, config.baseUrl, client),
    };
}
