import React from 'react';
import { RewardRootController, RewardRootLoader } from './rewardRootInterface';
import { RewardRootContainerProps, RewardRootViewProps } from './rewardRootProps';
import { useLocation, useNavigate } from 'react-router';
import { RewardContainerBaseConfig } from '../../base';
import { RewardErrorContainerProps } from '../common';

export function creatRewardRootContainer(
    config: RewardContainerBaseConfig,
    loader: RewardRootLoader,
    controller: RewardRootController,
    View: {
        Error: React.FC<RewardErrorContainerProps>;
    }
): React.FC<RewardRootContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: {
            root: { useContext },
        },
    } = config;

    const { Error: ErrorView } = View;

    const ErrorBoundary = config.UI.Application.createErrorBoundary({
        errorClass: Error,
    });

    return ({ children, ...containerProps }) => {
        const context = useContext();
        const data = loader.useLoad(context, containerProps);
        const props = controller.useProps(context, data, {
            navigation: {
                navigate: useNavigate(),
                location: useLocation(),
            },
        });
        return <>{children}</>;
    };
}
