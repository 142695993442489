import React, { Suspense } from 'react';
import { Select as ChakraSelect } from 'chakra-react-select';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Center,
    DarkMode,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuGroup,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Radio,
    RadioGroup,
    Select,
    SimpleGrid,
    Stack,
    StackDivider,
    Tag,
    Text,
    Tooltip,
    useToken,
    VStack,
} from '@chakra-ui/react';
import {
    AiOutlineCheck,
    AiOutlineLock,
    AiOutlinePlus,
    AiOutlineSearch,
    AiOutlineUserAdd,
} from 'react-icons/ai';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Controller, FieldError } from 'react-hook-form';
import { Graphics } from '../../../../../config/svg';
import { GRAY } from '../../../../../config';
import { CardSpinner } from '../../../../../../domain';
import { ApplicationWorkspaceViewProps } from '../../../../../shell/application';
import { ContributorRootViewProps } from '../../../../../shell';
import { ContributorThemeViewConfig } from '../../../base';
import { contributorThemeStyles } from '../../../style';

export function createContributorShellDesktopView(
    config: ContributorThemeViewConfig
): React.FC<ContributorRootViewProps> {
    const {
        UI: {
            Application: { Link, Anchor },
        },
    } = config;

    return (props) => {
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');
        return (
            <Grid
                w="100vw"
                h="100vh"
                templateRows="min-content 1fr"
                templateColumns="1fr"
            >
                {/* Header */}
                <GridItem
                    py={4}
                    px={6}
                    borderBottomWidth={2}
                    borderColor="whiteAlpha.300"
                >
                    <HStack justify="center">
                        <SimpleGrid
                            maxW={{ base: '64rem', '2xl': '76rem' }}
                            w="full"
                            columns={3}
                        >
                            <HStack w="full" justify="space-between">
                                <HStack spacing={12}>
                                    <Link {...props.home.link}>
                                        <Image
                                            src={Graphics.Brand.WhiteText}
                                            alt="brand"
                                        />
                                    </Link>
                                </HStack>
                            </HStack>
                            <HStack></HStack>
                            <HStack
                                color="whiteAlpha.800"
                                spacing={4}
                                justify="end"
                                divider={
                                    <StackDivider
                                        borderColor="whiteAlpha.100"
                                        borderWidth={1}
                                    />
                                }
                            >
                                <HStack spacing={4} fontSize="md" fontWeight="semibold">
                                    {props.navigation.view.navigation.items.map(
                                        (item) => (
                                            <Link key={item.id} {...item.link}>
                                                <HStack
                                                    aria-current={item.isActive}
                                                    w="full"
                                                    borderRadius="md"
                                                    py={1.5}
                                                    px={3}
                                                    cursor="pointer"
                                                    _hover={{ bg: 'whiteAlpha.50' }}
                                                    {...(item.isActive
                                                        ? { bg: 'whiteAlpha.100' }
                                                        : {})}
                                                >
                                                    <Text whiteSpace="nowrap">
                                                        {item.label}
                                                    </Text>
                                                    {item.tag && (
                                                        <Tag
                                                            {...item.tag}
                                                            size="sm"
                                                            fontWeight="bold"
                                                            color="blackAlpha.900"
                                                            bg="#c6e74f"
                                                            letterSpacing="wide"
                                                        />
                                                    )}
                                                </HStack>
                                            </Link>
                                        )
                                    )}
                                </HStack>
                                <HStack spacing={4}>
                                    <Popover
                                        {...props.account.menu}
                                        placement="bottom-start"
                                    >
                                        <PopoverTrigger>
                                            <Avatar cursor="pointer" size="sm" />
                                        </PopoverTrigger>
                                        <PopoverContent
                                            maxW="16rem"
                                            bg="#292929"
                                            _focus={{ outline: 'none' }}
                                            _focusVisible={{ outline: 'none' }}
                                        >
                                            <PopoverBody p={0}>
                                                <VStack
                                                    align="start"
                                                    w="full"
                                                    divider={
                                                        <StackDivider
                                                            borderColor="whiteAlpha.200"
                                                            // borderWidth={1}
                                                        />
                                                    }
                                                    spacing={0}
                                                >
                                                    <VStack
                                                        align="start"
                                                        w="full"
                                                        spacing={0}
                                                    >
                                                        <HStack
                                                            fontWeight="medium"
                                                            pt={3}
                                                            px={3}
                                                            spacing={3}
                                                        >
                                                            <Avatar size="sm" />
                                                            <VStack
                                                                align="start"
                                                                spacing={0}
                                                            >
                                                                <Text>
                                                                    {
                                                                        props.account.view
                                                                            .account.label
                                                                    }
                                                                </Text>
                                                                <Text
                                                                    fontSize="sm"
                                                                    color="whiteAlpha.600"
                                                                    userSelect="none"
                                                                >
                                                                    {
                                                                        props.account.view
                                                                            .account.role
                                                                    }
                                                                </Text>
                                                            </VStack>
                                                        </HStack>
                                                        <VStack
                                                            align="start"
                                                            w="full"
                                                            py={2}
                                                            spacing={0}
                                                        >
                                                            {props.account.view.actions.items.map(
                                                                (action) => (
                                                                    <Link
                                                                        key={action.id}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        {...action.link}
                                                                    >
                                                                        <Button
                                                                            py={2}
                                                                            px={3}
                                                                            fontWeight="inherit"
                                                                            borderRadius="none"
                                                                            bg="none"
                                                                            width="full"
                                                                            justifyContent="start"
                                                                            iconSpacing={
                                                                                3
                                                                            }
                                                                            leftIcon={
                                                                                <Icon
                                                                                    as={
                                                                                        action.Icon
                                                                                    }
                                                                                />
                                                                            }
                                                                            _hover={{
                                                                                bg: 'whiteAlpha.100',
                                                                            }}
                                                                        >
                                                                            {action.label}
                                                                        </Button>
                                                                    </Link>
                                                                )
                                                            )}
                                                        </VStack>
                                                    </VStack>
                                                    <HStack w="full" py={2}>
                                                        <Button
                                                            {...props.account.view.logout
                                                                .button}
                                                            py={2}
                                                            px={3}
                                                            fontWeight="inherit"
                                                            borderRadius="none"
                                                            bg="none"
                                                            width="full"
                                                            justifyContent="start"
                                                            iconSpacing={3}
                                                            leftIcon={
                                                                props.account.view.logout
                                                                    .Icon ? (
                                                                    <Icon
                                                                        as={
                                                                            props.account
                                                                                .view
                                                                                .logout
                                                                                .Icon
                                                                        }
                                                                    />
                                                                ) : undefined
                                                            }
                                                            _hover={{
                                                                bg: 'whiteAlpha.100',
                                                            }}
                                                        >
                                                            Logout
                                                        </Button>
                                                    </HStack>
                                                </VStack>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </HStack>
                            </HStack>
                        </SimpleGrid>
                    </HStack>
                </GridItem>
                <GridItem
                    ref={props.scroll.ref}
                    overflowY="scroll"
                    css={{
                        scrollbarGutter: 'stable both-edges',
                        '&::-webkit-scrollbar': {
                            '-webkit-appearance': 'none',
                            width: '0.5rem',
                        },
                        '&::-webkit-scrollbar-track': {},
                        '&::-webkit-scrollbar-corner': {
                            '-webkit-appearance': 'none',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '1rem',
                            background: scrollbarBg,
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            // background: scrollbarBg,
                            background: scrollbarHoverBg,
                        },
                    }}
                >
                    <Grid h="full" templateRows="1fr auto">
                        <GridItem>
                            <Suspense
                                fallback={
                                    <Center
                                        h="full"
                                        w="full"
                                        minH={contributorThemeStyles.minScreenHeight}
                                    >
                                        <CardSpinner />
                                    </Center>
                                }
                            >
                                <VStack
                                    w="full"
                                    align="start"
                                    px={{ base: 3, sm: 6, md: 12 }}
                                >
                                    {props.children}
                                </VStack>
                            </Suspense>
                        </GridItem>
                        <GridItem>
                            <HStack
                                borderTopWidth={2}
                                borderColor="whiteAlpha.200"
                                mt={4}
                                py={{ base: 12, xl: 16 }}
                                w="full"
                                justify="center"
                            >
                                <SimpleGrid
                                    maxW="64rem"
                                    w="full"
                                    columns={{ base: 2, md: 4 }}
                                    gap={24}
                                >
                                    <HStack w="full" align="top">
                                        <Link {...props.home.link}>
                                            <Image
                                                src={Graphics.Brand.WhiteText}
                                                w={32}
                                                alt="brand"
                                            />
                                        </Link>
                                    </HStack>
                                    {props.footer.groups.map((group) => (
                                        <VStack
                                            key={group.id}
                                            align="start"
                                            w="full"
                                            spacing={2}
                                        >
                                            <Text
                                                fontWeight="semibold"
                                                color="whiteAlpha.800"
                                            >
                                                {group.label}
                                            </Text>
                                            <VStack
                                                align="start"
                                                color="whiteAlpha.600"
                                                spacing={2}
                                            >
                                                {group.links.map((link) => (
                                                    <Anchor
                                                        key={link.id}
                                                        {...link.anchor}
                                                    >
                                                        {link.label}
                                                    </Anchor>
                                                ))}
                                            </VStack>
                                        </VStack>
                                    ))}
                                    {/* <VStack align="start" w="full" spacing={2}>
                                        <Text
                                            fontWeight="semibold"
                                            color="whiteAlpha.800"
                                        >
                                            Varos
                                        </Text>
                                        <VStack
                                            align="start"
                                            color="whiteAlpha.600"
                                            spacing={2}
                                        >
                                            <Text>Home</Text>
                                            <Text>How Varos works</Text>
                                            <Text>About Us</Text>
                                            <Text>Integrations</Text>
                                            <Text>Blog</Text>
                                            <Text>Videos</Text>
                                        </VStack>
                                    </VStack>
                                    <VStack align="start" w="full" spacing={2}>
                                        <Text
                                            fontWeight="semibold"
                                            color="whiteAlpha.800"
                                        >
                                            Information
                                        </Text>
                                        <VStack
                                            align="start"
                                            color="whiteAlpha.600"
                                            spacing={2}
                                        >
                                            <Text>Jobs</Text>
                                            <Text>Privacy Policy</Text>
                                            <Text>Terms of service</Text>
                                        </VStack>
                                    </VStack>
                                    <VStack align="start" w="full" spacing={2}>
                                        <Text
                                            fontWeight="semibold"
                                            color="whiteAlpha.800"
                                        >
                                            Partners
                                        </Text>
                                        <VStack
                                            align="start"
                                            color="whiteAlpha.600"
                                            spacing={2}
                                        >
                                            <Text>Become a partner</Text>
                                        </VStack>
                                    </VStack> */}
                                </SimpleGrid>
                            </HStack>
                            <HStack
                                borderTopWidth={2}
                                borderColor="whiteAlpha.100"
                                py={8}
                                w="full"
                                justify="center"
                                color="whiteAlpha.500"
                            >
                                <HStack>
                                    <Text>{props.footer.copyright.label}</Text>
                                </HStack>
                            </HStack>
                        </GridItem>
                    </Grid>
                </GridItem>
            </Grid>
        );
    };
}
