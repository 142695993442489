import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    BoxProps,
    Button,
    ButtonGroup,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    SimpleGrid,
    StackDivider,
    Tag,
    Text,
    useToken,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import React from 'react';
import { Graphics, Images } from '../../../../../config/svg';
import { VAROS_YELLOW } from '../../../../../config';
import { RewardViewBaseConfig } from '../../../base';
import { OfferOverviewViewProps } from './offerOverviewProps';
import { rewardStyles } from '../../../constant';
import { MdOpenInNew } from 'react-icons/md';
import { BsArrowRightShort } from 'react-icons/bs';
import { AiOutlineSearch } from 'react-icons/ai';
import { OfferItemViewProps } from '../../../fragment';

export function createOfferOverviewView(
    config: RewardViewBaseConfig
): React.FC<OfferOverviewViewProps> {
    const {
        UI: {
            Application: { Link, Anchor },
        },
    } = config;

    const OfferCard: React.FC<OfferItemViewProps> = (props) => (
        <VStack
            align="start"
            w="full"
            p={6}
            bg="whiteAlpha.100"
            borderRadius="xl"
            spacing={4}
        >
            <HStack spacing={4}>
                <Box
                    // borderWidth={1}
                    // borderColor="whiteAlpha.300"
                    bg="whiteAlpha.100"
                    borderRadius="lg"
                    flexShrink={0}
                    p={2}
                    userSelect="none"
                >
                    <Image w={8} h={8} fit="contain" {...props.image} />
                </Box>
                <VStack align="start" w="full" spacing={1}>
                    <Link {...props.link}>
                        <Text fontWeight="semibold" color="whiteAlpha.900" fontSize="lg">
                            {props.label}
                        </Text>
                    </Link>
                    <Wrap shouldWrapChildren={true} userSelect="none">
                        {props.tags.map((tag) => (
                            <Tag key={tag.id} colorScheme="blue" variant="subtle">
                                {tag.label}
                            </Tag>
                        ))}
                    </Wrap>
                </VStack>
            </HStack>
            <Text color="whiteAlpha.700" noOfLines={2}>
                {props.caption}
            </Text>
            <HStack w="full" justify="space-between">
                <Link {...props.link}>
                    <Button
                        size="sm"
                        rightIcon={<Icon fontSize="xl" as={BsArrowRightShort} />}
                        bg="whiteAlpha.50"
                        _hover={{ bg: 'whiteAlpha.100' }}
                    >
                        Claim reward
                    </Button>
                </Link>
                <Link {...props.link}>
                    <Button size="sm" variant="link">
                        View details
                    </Button>
                </Link>
            </HStack>
        </VStack>
    );

    return (props) => {
        const [gradientStart, gradientEnd] = useToken('colors', [
            'whiteAlpha.50',
            'whiteAlpha.200',
        ]);
        return (
            <HStack w="full" justify="center" py={12}>
                <VStack
                    maxW={rewardStyles.content.maxW}
                    align="start"
                    w="full"
                    spacing={12}
                >
                    <HStack w="full" justify="space-between">
                        <HStack>
                            <Heading fontWeight="medium" fontSize="2xl">
                                Varos Rewards
                            </Heading>
                        </HStack>
                        <ButtonGroup>
                            <Button
                                {...props.request.action.button}
                                variant="outline"
                                borderColor="whiteAlpha.300"
                                bg="none"
                                _hover={{ bg: 'whiteAlpha.50' }}
                                rightIcon={<Icon as={MdOpenInNew} />}
                            >
                                {props.request.action.label}
                            </Button>
                        </ButtonGroup>
                    </HStack>
                    {/* banner */}
                    <VStack
                        align="start"
                        w="full"
                        spacing={0}
                        borderRadius="xl"
                        borderEndRadius="xl"
                        bgGradient={`linear(to-br, ${gradientStart} 0%, ${gradientStart} 30%, ${gradientEnd} 100%)`}
                        divider={
                            <StackDivider borderWidth={1} borderColor="whiteAlpha.100" />
                        }
                    >
                        <Grid
                            w="full"
                            templateColumns={{ base: '1fr', md: '2fr 1fr' }}
                            p={8}
                            columnGap={{ base: 16 }}
                            rowGap={{ base: 8 }}
                        >
                            <GridItem>
                                <VStack
                                    align="start"
                                    spacing={6}
                                    maxW="32rem"
                                    h="full"
                                    w="full"
                                >
                                    <VStack
                                        align="start"
                                        spacing={3}
                                        w="full"
                                        borderColor={VAROS_YELLOW['200']}
                                        borderLeftWidth={4}
                                        pl={8}
                                    >
                                        <Heading
                                            fontSize="2xl"
                                            fontWeight="medium"
                                            color="whiteAlpha.900"
                                        >
                                            Get Rewarded for Sharing Your Expertise
                                        </Heading>
                                        <Text fontSize="lg" color="whiteAlpha.700">
                                            Participate in our AI-powered interview to
                                            share your insights, earn rewards, and help
                                            shape actionable research for Enterprise Tech
                                            Execs
                                        </Text>
                                    </VStack>
                                </VStack>
                            </GridItem>
                            <GridItem>
                                <HStack h="full" w="full" justify="end">
                                    <ButtonGroup
                                        spacing={8}
                                        size="lg"
                                        alignItems="center"
                                    >
                                        <Anchor {...props.hero.action.learn.anchor}>
                                            <Button
                                                variant="link"
                                                borderRadius="full"
                                                _hover={{
                                                    bg: 'inherit',
                                                    textDecor: 'underline',
                                                }}
                                                _focus={{
                                                    bg: 'inherit',
                                                    outline: 'none',
                                                }}
                                            >
                                                {props.hero.action.learn.label}
                                            </Button>
                                        </Anchor>
                                        {props.hero.action.start && (
                                            <Link {...props.hero.action.start.link}>
                                                <Button
                                                    bg={VAROS_YELLOW['200']}
                                                    color="black"
                                                    borderRadius="full"
                                                    _hover={{ bg: VAROS_YELLOW['200'] }}
                                                    _focus={{ bg: VAROS_YELLOW['200'] }}
                                                >
                                                    {props.hero.action.start.label}
                                                </Button>
                                            </Link>
                                        )}
                                    </ButtonGroup>
                                </HStack>
                            </GridItem>
                        </Grid>
                        <HStack justify="center" w="full" p={8}>
                            <VStack w="full" spacing={4} align="center">
                                <SimpleGrid
                                    w="full"
                                    color="whiteAlpha.500"
                                    columns={{ base: 2, md: 2, xl: 4 }}
                                    rowGap={8}
                                    columnGap={24}
                                    fontSize="lg"
                                    fontWeight="medium"
                                    justifyItems="center"
                                >
                                    <HStack textAlign="center">
                                        <Text
                                            color="whiteAlpha.400"
                                            fontWeight="bold"
                                            letterSpacing="wide"
                                            textTransform="uppercase"
                                            fontSize="sm"
                                        >
                                            Backed by leading VCs
                                        </Text>
                                    </HStack>
                                    <HStack h="full" align="center">
                                        <Anchor
                                            id="offer_overview_hero_investor_ibex"
                                            href="https://www.ibexinvestors.com"
                                            target="_blank"
                                        >
                                            <Image
                                                filter="grayscale(100%) opacity(50%)"
                                                w={32}
                                                src={Images.Investor.Ibex}
                                                alt="brand"
                                            />
                                        </Anchor>
                                    </HStack>
                                    <HStack h="full" align="center">
                                        <Anchor
                                            id="offer_overview_hero_investor_ycombinator"
                                            href="https://www.ycombinator.com"
                                            target="_blank"
                                        >
                                            <Image
                                                filter="grayscale(100%) opacity(50%)"
                                                w={36}
                                                src={Graphics.Investor.YCombinator}
                                                alt="brand"
                                            />
                                        </Anchor>
                                    </HStack>
                                    <HStack h="full" align="center">
                                        <Anchor
                                            id="offer_overview_hero_investor_mosaic"
                                            href="https://mgp.vc"
                                            target="_blank"
                                        >
                                            <Image
                                                filter="grayscale(100%) opacity(50%)"
                                                w={32}
                                                src={Graphics.Investor.Mosaic}
                                                alt="brand"
                                            />
                                        </Anchor>
                                    </HStack>
                                </SimpleGrid>
                            </VStack>
                        </HStack>
                    </VStack>
                    {/* Promotions */}
                    {props.promotions && (
                        <VStack align="start" w="full" spacing={4}>
                            <Heading
                                color="whiteAlpha.900"
                                fontWeight="medium"
                                fontSize="xl"
                            >
                                Special offers for you
                            </Heading>
                            <VStack align="start" w="full" spacing={4}>
                                <Alert w="full" status="info">
                                    <AlertIcon />
                                    <AlertTitle w="full">
                                        <HStack w="full" justify="space-between">
                                            <Text>
                                                You have been invited to claim these
                                                custom offers
                                            </Text>
                                            <Button variant="link">Learn more</Button>
                                        </HStack>
                                    </AlertTitle>
                                </Alert>
                                <SimpleGrid
                                    w="full"
                                    columns={{ base: 1, md: 2, '2xl': 3 }}
                                    gap={6}
                                >
                                    {props.promotions.items.map((item) => (
                                        <OfferCard key={item.id} {...item} />
                                    ))}
                                </SimpleGrid>
                            </VStack>
                        </VStack>
                    )}
                    {/* Search */}
                    {props.search && (
                        <VStack align="start" w="full" spacing={4}>
                            <Heading
                                color="whiteAlpha.900"
                                fontWeight="medium"
                                fontSize="xl"
                            >
                                All offers
                            </Heading>
                            <HStack w="full">
                                <InputGroup w="full" size="lg">
                                    <Input
                                        {...props.search.input.input}
                                        borderRadius="full"
                                        _focus={{ outline: 'none', shadow: 'none' }}
                                    />
                                    <InputLeftElement>
                                        <Icon
                                            color="whiteAlpha.500"
                                            as={AiOutlineSearch}
                                        />
                                    </InputLeftElement>
                                </InputGroup>
                            </HStack>
                            {props.search.input.empty && (
                                <HStack w="full" justify="center">
                                    <Text fontWeight="medium" color="whiteAlpha.600">
                                        {props.search.input.empty.label}
                                    </Text>
                                </HStack>
                            )}
                            {!props.search.input.empty && (
                                <VStack align="start" w="full" spacing={4}>
                                    <HStack w="full">
                                        <Text
                                            color="whiteAlpha.500"
                                            fontSize="sm"
                                            fontWeight="medium"
                                        >
                                            {props.search.pagination.label}
                                        </Text>
                                    </HStack>
                                    <SimpleGrid
                                        w="full"
                                        columns={{ base: 1, md: 2, '2xl': 3 }}
                                        gap={6}
                                    >
                                        {props.search.items.map((item) => (
                                            <OfferCard key={item.id} {...item} />
                                        ))}
                                    </SimpleGrid>
                                </VStack>
                            )}
                        </VStack>
                    )}
                </VStack>
            </HStack>
        );
    };
}
