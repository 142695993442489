import { PageIds } from '../../../../../config';
import { buildStatusProps } from '../../../../../view/common';
import { RewardControllerBaseConfig } from '../../../base';
import { buildOfferItemProps } from '../../../fragment';
import { OfferDetailViewProps } from './offerDetailProps';
import { OfferDetailController } from './offerDetailInterface';

export function createOfferDetailController(
    config: RewardControllerBaseConfig
): OfferDetailController {
    const {
        kernel: {
            provider: { createPageController },
        },
    } = config;

    const pageController = createPageController({
        id: PageIds.REWARD_OFFER_DETAIL,
    });

    return {
        useProps(context, item, props): OfferDetailViewProps {
            const page = pageController.useProps(
                {
                    entity: item.offer.root,
                },
                {
                    metadata: {
                        offer_id: item.offer.root.id,
                        offer_slug: item.offer.root.slug,
                        offer_version: item.offer.root.version,
                        offer_promoted: !!item.offer.promotion,
                    },
                }
            );

            const completed = item.eligibility.tasks.filter(
                (candidate) => candidate.status === 'completed'
            ).length;

            const total = item.eligibility.tasks.length;
            const isEligible = item.eligibility.status === 'eligible';

            return {
                discover: {
                    link: {
                        to: '../',
                        replace: true,
                    },
                },
                root: {
                    status: buildStatusProps({
                        kind: 'warning',
                        label: 'Unclaimed',
                        value: 'pending',
                        description: `Offer is available but not yet claimed`,
                        ordinal: null,
                    }),
                },
                task: {
                    summary: {
                        label: `${completed}/${total} fulfilled`,
                        colorScheme: isEligible ? 'green' : 'orange',
                    },
                    progress: {
                        // add some gutter (10%) to better show empty progress
                        value: total > 0 ? Math.round(10 + (completed / total) * 95) : 1,
                        min: 1,
                        max: 100,
                    },
                },
                terms: props.terms,
                item: buildOfferItemProps(config, item.offer, {
                    location: props.location,
                }),
                related: item.related
                    ? item.related.map((item) =>
                          buildOfferItemProps(config, item, { location: props.location })
                      )
                    : null,
            };
        },
    };
}
