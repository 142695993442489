import { PageIds } from '../../../../../config';
import { RewardControllerBaseConfig } from '../../../base';
import {
    buildOfferItemProps,
    OfferItemAggregate,
    OfferItemViewProps,
} from '../../../fragment';
import { OfferOverviewController } from './offerOverviewInterface';
import { OfferOverviewViewProps } from './offerOverviewProps';

export function createOfferOverviewController(
    config: RewardControllerBaseConfig
): OfferOverviewController {
    const {
        controller: { createSearch: createSearchController },
        kernel: {
            provider: { createPageController, createActionController },
        },
    } = config;
    const pageControler = createPageController({
        id: PageIds.REWARD_OFFER_OVERVIEW,
    });
    const requestOfferController = createActionController({
        id: 'offer_overview_request_offer',
    });
    const searchController = createSearchController<OfferItemAggregate>({
        id: 'offer_overview_search',
        placeholder: 'Search offers',
        empty: 'No offers found',
        debounceMs: 50,
        getProps(item) {
            return {
                id: item.root.id,
                term: item.root.name,
            };
        },
    });
    return {
        useProps(context, item, props): OfferOverviewViewProps {
            const page = pageControler.useProps({}, {});
            const request = requestOfferController.useProps(props.request);
            const search = searchController.useProps(props.search);
            const [promotion] = item.promotions;
            return {
                request: {
                    action: request,
                },
                hero: {
                    action: {
                        learn: props.action.learn,
                        start: promotion
                            ? {
                                  label: 'Get started',
                                  link: {
                                      id: 'rewards_hero_get_started',
                                      ...config.navigation.offer(promotion.root),
                                  },
                              }
                            : null,
                    },
                },
                promotions:
                    item.promotions.length === 0
                        ? null
                        : {
                              items: item.promotions.map((item) =>
                                  buildOfferItemProps(config, item, props)
                              ),
                          },
                search:
                    item.search.length === 0
                        ? null
                        : {
                              input: search,
                              pagination: {
                                  label: `Showing ${search.matched} of ${item.search.length} offers`,
                              },
                              items: item.search.flatMap((item) => {
                                  const searchProps = search.getItemProps(item);
                                  if (!searchProps.isVisible) {
                                      return [];
                                  }
                                  return [buildOfferItemProps(config, item, props)];
                              }),
                          },
            };
        },
    };
}
