import { useQueryClient } from '@tanstack/react-query';
import { RewardSdk } from '@varos/contribute-sdk';
import { Kernel } from '../../../base';
import { RewardOfferRepository } from './rewardOfferInterface';
import { RewardLookupProps } from './rewardOfferProps';

export function createRewardOfferRepository(
    kernel: Kernel,
    api: RewardSdk
): RewardOfferRepository {
    const {
        infra: { repository },
    } = kernel;
    const PREFIX = ['reward', 'offer'];
    function getLookupKey(props: RewardLookupProps) {
        return [...PREFIX, 'lookup', props];
    }
    return {
        useFind(context, props, options) {
            const queryClient = useQueryClient();
            const query = repository.useQuery({
                ...options,
                queryKey: [...PREFIX, 'find', props],
                async queryFn() {
                    const response = await api.contributor.offerList({
                        limit: props.limit,
                    });
                    return response.data.data;
                },
                // onSuccess(data) {
                //     for (const item of data) {
                //         const queryKey = getLookupKey({ offer: item });
                //         queryClient.setQueryData(queryKey, item);
                //     }
                // },
            });
            return query;
        },
        useLookup(context, props, options) {
            const query = repository.useQuery({
                ...options,
                queryKey: getLookupKey(props),
                async queryFn() {
                    api.admin;
                    const response = await api.contributor.offerRetrieve({
                        offerId: props.offer.id,
                    });
                    return response.data;
                },
            });
            return query;
        },
    };
}
