import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { SupportLinks } from '../../../../../config';
import { RewardContainerBaseConfig } from '../../../base';
import { OfferOverviewController, OfferOverviewLoader } from './offerOverviewInterface';
import { OfferOverviewViewProps } from './offerOverviewProps';

export function createOfferOverviewContainer(
    config: RewardContainerBaseConfig,
    loader: OfferOverviewLoader,
    controller: OfferOverviewController,
    View: React.FC<OfferOverviewViewProps>
): React.FC {
    const {
        kernel,
        context: {
            root: { useContext },
        },
    } = config;
    return (containerProps) => {
        const location = useLocation();
        const context = useContext();
        const [searchTerm, setSearchTerm] = useState('');
        const data = loader.useLoad(context, {});
        const props = controller.useProps(context, data, {
            location,
            search: {
                items: data.search,
                value: searchTerm,
                onChange: setSearchTerm,
            },
            request: {
                kind: 'custom',
                name: 'Request a reward',
                onPerform() {
                    kernel.infra.help.adapter.open();
                },
            },
            action: {
                learn: {
                    label: 'Learn more',
                    anchor: {
                        id: 'rewards_hero_learn_more',
                        href: SupportLinks.REWARDS_LEARN_MORE,
                        target: '_blank',
                    },
                },
            },
        });
        return <View {...props} />;
    };
}
