import React, { useCallback, useState } from 'react';
import { ApiError } from '../../../../../base';
import { RewardContainerBaseConfig } from '../../../base';
import { OfferDetailController, OfferDetailLoader } from './offerDetailInterface';
import { OfferDetailUrlParamSchema } from './offerDetailSchema';
import { OfferDetailContainerProps, OfferDetailViewProps } from './offerDetailProps';
import { Navigate, useLocation, useNavigate } from 'react-router';

export function createOfferDetailContainer(
    config: RewardContainerBaseConfig,
    loader: OfferDetailLoader,
    controller: OfferDetailController,
    View: React.FC<OfferDetailViewProps>
): React.FC<OfferDetailContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },

        context: {
            root: { useContext },
        },
    } = config;
    return ({ ...containerProps }) => {
        const context = useContext();
        const options = useOptions(OfferDetailUrlParamSchema);
        try {
            const data = loader.useLoad(context, options);
            const props = controller.useProps(context, data, {
                location: useLocation(),
                terms: {
                    label: 'Terms of service',
                    link: {
                        id: 'offer_detail_terms_of_service',
                        to: '/legal/terms-of-service',
                        target: '_blank',
                    },
                },
            });
            return <View {...props} />;
        } catch (error) {
            // HACK can remove once we implement persistence for rewards model
            // TODO use error boundary
            if (error instanceof ApiError && error.statusCode === 404) {
                console.error('error', error);
                return <Navigate to="../" replace={true} />;
            }
            throw error;
        }
    };
}
