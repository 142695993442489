import { useLocation, useNavigate } from 'react-router';
import React from 'react';
import { ErrorViewProps } from '../../../../../base';
import { RewardContainerBaseConfig } from '../../../base';
import { RewardErrorContainerProps } from './rewardErrorProps';

export function createRewardErrorContainer(
    config: RewardContainerBaseConfig,
    View: React.FC<ErrorViewProps>
): React.FC<RewardErrorContainerProps> {
    const controller = config.kernel.provider.createErrorController({
        id: 'rewards',
    });
    return ({ ...containerProps }) => {
        const navigate = useNavigate();
        const location = useLocation();
        const props = controller.useProps({ ...containerProps, location, navigate });
        return <View {...props} />;
    };
}
