import { useLayoutEffect } from 'react';
import { ApplicationEntryEnhancer, ApplicationPluginProvider } from '../../entrypoint';
import { ShellLayoutController, ApplicationShellEnhancer } from '../../shell';
import { createSettingAccountRoute, SettingAccountController } from '../../route';
import { useAccountContextV2 } from '../../context';
import { OnboardingPluginConfig } from './onboardingPluginConfig';

/**
 * Adds onboarding redirects and hides application menus and navigation when account has not finished onboarding
 * @param config
 */
export function createOnboardingPlugin(
    init: OnboardingPluginConfig
): ApplicationPluginProvider {
    const { allowedPagePatterns, forceOnboarding } = init;
    const onboardingUrl = `/u/onboarding?step=integration`;

    function createShellEnhancer(): ApplicationShellEnhancer {
        return (create) => (config) => {
            function enhanceRootController(
                instance: ShellLayoutController
            ): ShellLayoutController {
                return {
                    ...instance,
                    useProps(context, item, props) {
                        let mappedProps = props;
                        const isOnboarding =
                            !item.account.account?.state.finishedOnBoarding ||
                            forceOnboarding;

                        useLayoutEffect(() => {
                            if (!isOnboarding) {
                                // onboarding finished do not redirect
                                return;
                            }
                            if (
                                allowedPagePatterns.some((pattern) =>
                                    props.location.pathname.includes(pattern)
                                )
                            ) {
                                // page is allowed during onboarding do not redirect
                                return;
                            }
                            console.log(
                                'application not available onboarding still in progress, redirecting...'
                            );
                            props.navigate(onboardingUrl, {
                                replace: true,
                            });
                        }, [isOnboarding, props.location.pathname]);

                        if (isOnboarding) {
                            mappedProps = {
                                ...mappedProps,
                                home: {
                                    ...mappedProps.home,
                                    link: {
                                        ...mappedProps.home.link,
                                        to: onboardingUrl,
                                    },
                                },
                                status: {
                                    kind: 'warning',
                                    label: 'Onboarding not yet completed',
                                    action: {
                                        label: 'Go to onboarding',
                                        link: {
                                            to: onboardingUrl,
                                        },
                                    },
                                },
                                navigation: { ...props.navigation, items: [] },
                                shortcuts: {
                                    groups: [],
                                },
                            };
                        }

                        return instance.useProps(context, item, mappedProps);
                    },
                };
            }
            return create({
                ...config,
                provider: {
                    ...config.provider,
                    createRootController(...args) {
                        const instance = config.provider.createRootController(...args);
                        return enhanceRootController(instance);
                    },
                },
            });
        };
    }

    function createRootEnhancer(): ApplicationEntryEnhancer {
        return (create) => (config) => {
            function enhanceRouteController(
                instance: SettingAccountController
            ): SettingAccountController {
                return {
                    ...instance,
                    useProps(...args) {
                        const context = useAccountContextV2();
                        const isOnboarding =
                            !context.data.account?.state.finishedOnBoarding ||
                            forceOnboarding;
                        const props = instance.useProps(...args);
                        let mappedProps = props;
                        if (isOnboarding) {
                            mappedProps = {
                                ...mappedProps,
                                isVisible(item) {
                                    return allowedPagePatterns.some((pattern) =>
                                        item.path.includes(
                                            // setting items are not abasolute
                                            pattern.replace(/^\//, '')
                                        )
                                    );
                                },
                            };
                        }
                        return mappedProps;
                    },
                };
            }
            function enhanceRouteProvider(
                provider: typeof createSettingAccountRoute
            ): typeof createSettingAccountRoute {
                return (config) => {
                    return provider({
                        ...config,
                        controller: {
                            ...config.controller,
                            route: enhanceRouteController(config.controller.route),
                        },
                    });
                };
            }
            return create({
                ...config,
                route: {
                    ...config.route,
                    settings: {
                        ...config.route.settings,
                        createOrganization: enhanceRouteProvider(
                            config.route.settings.createOrganization
                        ),
                    },
                },
            });
        };
    }

    return {
        create(config) {
            return {
                shell: {
                    application: createShellEnhancer(),
                },
                root: createRootEnhancer(),
            };
        },
    };
}
