import {
    Alert,
    AlertIcon,
    AlertTitle,
    Button,
    ButtonGroup,
    Center,
    HStack,
    Icon,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { ErrorViewProps } from '../../../../../base';
import { RewardViewBaseConfig } from '../../../base';
import { AiOutlineWarning } from 'react-icons/ai';

export function createRewardErrorView(
    config: RewardViewBaseConfig
): React.FC<ErrorViewProps> {
    const {
        UI: {
            Application: { Link },
        },
    } = config;
    return (props) => {
        return (
            <HStack h="full" w="full" justify="center" align="center" py={8}>
                <Center w="full" h="full">
                    <VStack align="start" p={12} spacing={6}>
                        <Icon w={12} h={12} color="red.500" as={AiOutlineWarning} />
                        <VStack align="start" color="whiteAlpha.900" spacing={1}>
                            <Text fontWeight="medium" letterSpacing="wide">
                                {props.error.title}
                            </Text>
                            <Text color="whiteAlpha.700">{props.error.hint}</Text>
                        </VStack>
                        <ButtonGroup spacing={6}>
                            <Button
                                {...props.action.button}
                                variant="outline"
                                borderColor="whiteAlpha.300"
                                borderRadius="full"
                                bg="none"
                                leftIcon={<Icon as={props.action.Icon} />}
                                _hover={{ bg: 'whiteAlpha.50' }}
                            >
                                {props.action.label}
                            </Button>
                        </ButtonGroup>
                    </VStack>
                </Center>
            </HStack>
        );
    };
}
