import React, { Suspense } from 'react';

import {
    Avatar,
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    Image,
    SimpleGrid,
    Tag,
    Text,
    VStack,
} from '@chakra-ui/react';
import { AiOutlineMenu } from 'react-icons/ai';
import { Graphics } from '../../../../../config/svg';
import {
    ContributorAccountViewProps,
    ContributorRootViewProps,
} from '../../../../../shell';
import { ContributorThemeViewConfig } from '../../../base';
import { ErrorViewProps } from '../../../../../base';

export function createContributorShellMobileView(
    config: ContributorThemeViewConfig
): React.FC<ContributorRootViewProps> {
    const {
        UI: {
            Application: { Link },
        },
    } = config;

    const NavigationView: React.FC<ContributorRootViewProps> = (props) => {
        return (
            <Drawer {...props.navigation.view.drawer} id="workspace" placement="left">
                <DrawerOverlay />
                <DrawerContent bg="#292929">
                    <DrawerCloseButton
                        _active={{ outline: 'none' }}
                        _focus={{ outline: 'none' }}
                        // right={2}
                        // top={2}
                    />
                    <DrawerHeader
                        pt={8}
                        px={8}
                        pb={2}
                        fontSize="lg"
                        fontWeight="semibold"
                    >
                        <Text>Workspace</Text>
                    </DrawerHeader>
                    <Grid
                        w="full"
                        h="full"
                        p={8}
                        pt={0}
                        gap={8}
                        templateRows="1fr min-content"
                    >
                        <GridItem overflowY="auto">
                            <VStack
                                align="start"
                                w="full"
                                spacing={
                                    props.navigation.view.navigation.items.length > 1
                                        ? 8
                                        : 3
                                }
                            >
                                <VStack
                                    align="start"
                                    w="full"
                                    color="whiteAlpha.800"
                                    spacing={0}
                                >
                                    {props.navigation.view.navigation.items.map(
                                        (item, index) => (
                                            <Link
                                                key={item.id}
                                                style={{ width: '100%' }}
                                                {...item.link}
                                            >
                                                <HStack
                                                    w="full"
                                                    justify="space-between"
                                                    borderRadius="md"
                                                    py={2}
                                                    px={3}
                                                    {...(item.isActive
                                                        ? { bg: 'whiteAlpha.100' }
                                                        : {})}
                                                >
                                                    <HStack spacing={4}>
                                                        <Icon
                                                            fontSize="xl"
                                                            as={item.Icon}
                                                        />
                                                        <Text>{item.label}</Text>
                                                    </HStack>
                                                    {item.tag && (
                                                        <Tag
                                                            {...item.tag}
                                                            size="sm"
                                                            fontWeight="bold"
                                                            color="blackAlpha.900"
                                                            bg="#c6e74f"
                                                            letterSpacing="wide"
                                                        />
                                                    )}
                                                </HStack>
                                            </Link>
                                        )
                                    )}
                                </VStack>
                            </VStack>
                        </GridItem>
                        <GridItem>
                            <VStack
                                align="start"
                                w="full"
                                borderTopWidth={2}
                                borderColor="whiteAlpha.200"
                                pt={4}
                                color="whiteAlpha.800"
                                spacing={0}
                            >
                                {/* <HStack
                                    w="full"
                                    justify="space-between"
                                    borderRadius="md"
                                    py={2}
                                    px={3}
                                >
                                    <HStack spacing={4}>
                                        <Icon fontSize="xl" as={AiOutlineSetting} />
                                        <Text>Settings</Text>
                                    </HStack>
                                </HStack> */}
                                {/* <HStack
                                    w="full"
                                    justify="space-between"
                                    borderRadius="md"
                                    py={2}
                                    px={3}
                                >
                                    <HStack spacing={4}>
                                        <Icon fontSize="xl" as={AiOutlineInfoCircle} />
                                        <Text>Help</Text>
                                    </HStack>
                                </HStack> */}
                            </VStack>
                        </GridItem>
                    </Grid>
                </DrawerContent>
            </Drawer>
        );
    };

    const AccountView: React.FC<ContributorAccountViewProps> = (props) => {
        return (
            <Drawer {...props.drawer} id="account" placement="bottom">
                <DrawerOverlay />
                <DrawerContent h="95dvh" borderTopRadius="xl" bg="#292929">
                    <DrawerCloseButton
                        _focus={{ outline: 'none' }}
                        _focusVisible={{ outline: 'none' }}
                    />
                    <DrawerHeader px={6}>Account</DrawerHeader>
                    <Grid h="full" templateRows="1fr min-content">
                        <GridItem>
                            <VStack align="start" w="full" px={3}>
                                <HStack spacing={3} px={3}>
                                    <Avatar size="sm" />
                                    <VStack align="start" spacing={0}>
                                        <Text fontWeight="semibold">
                                            {props.account.label}
                                        </Text>
                                        <Text color="whiteAlpha.600">
                                            {props.account.role}
                                        </Text>
                                    </VStack>
                                </HStack>
                                <VStack align="start" w="full" py={2} spacing={0}>
                                    {props.actions.items.map((item, index) => (
                                        <Link
                                            key={item.id}
                                            style={{ width: '100%' }}
                                            {...item.link}
                                        >
                                            <HStack
                                                w="full"
                                                justify="space-between"
                                                borderRadius="md"
                                                py={2}
                                                px={3}
                                            >
                                                <HStack spacing={4}>
                                                    <Icon fontSize="xl" as={item.Icon} />
                                                    <Text>{item.label}</Text>
                                                </HStack>
                                            </HStack>
                                        </Link>
                                    ))}
                                </VStack>
                            </VStack>
                        </GridItem>
                        <GridItem>
                            <HStack w="full" px={3} py={12} justify="center">
                                <Button
                                    {...props.logout.button}
                                    w="full"
                                    borderRadius="full"
                                    leftIcon={
                                        props.logout.Icon ? (
                                            <Icon as={props.logout.Icon} />
                                        ) : undefined
                                    }
                                >
                                    Logout
                                </Button>
                            </HStack>
                        </GridItem>
                    </Grid>
                </DrawerContent>
            </Drawer>
        );
    };

    return (props) => {
        return (
            <Grid
                // add gutter when bottom menu is open for more visual clarity
                {...(props.account.menu.isOpen
                    ? { p: 6, borderRadius: 'lg', overflow: 'hidden' }
                    : undefined)}
                transition="all 0.5s ease"
                templateRows="min-content 1fr"
                templateColumns="1fr"
                h="full"
                w="full"
            >
                <GridItem>
                    <HStack w="full">
                        <SimpleGrid w="full" columns={3} px={3} py={2}>
                            <HStack h="full">
                                <IconButton
                                    {...props.navigation.trigger.button}
                                    aria-label="navigation"
                                    size="md"
                                    icon={<Icon fontSize="lg" as={AiOutlineMenu} />}
                                    bg="none"
                                    _focus={{ outline: 'none' }}
                                    _hover={{ bg: 'whiteAlpha.50' }}
                                />
                            </HStack>
                            <HStack w="full" justify="center" align="center">
                                <Link {...props.home.link}>
                                    <Image
                                        w={16}
                                        src={Graphics.Brand.WhiteText}
                                        alt="brand"
                                    />
                                </Link>
                            </HStack>
                            <HStack h="full" justify="end">
                                <Avatar
                                    size="sm"
                                    onClick={props.account.trigger.button.onClick}
                                />
                            </HStack>
                        </SimpleGrid>
                    </HStack>
                </GridItem>
                <GridItem overflowY="scroll">
                    {/* there is an issue that the drawer keeps blocking the screen unless removed explicitly */}
                    {props.navigation.menu.isOpen && <NavigationView {...props} />}
                    {/* there is an issue that the drawer keeps blocking the screen unless removed explicitly */}
                    {props.account.menu.isOpen && <AccountView {...props.account.view} />}
                    {/* {props.children} */}
                    <VStack w="full" align="start" px={{ base: 3, sm: 6, md: 12 }}>
                        {props.children}
                    </VStack>
                </GridItem>
            </Grid>
        );
    };
}
