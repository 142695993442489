import { z } from 'zod';

export const EnvironmentVariablesSchema = z.object({
    DEMO_API_KEY: z.string().optional(),
    STRIPE_PUBLIC_KEY: z.string().default('NOTSET'),
    ASSISTANT_API_BASE_URL: z.string().default('/api/v1/assistant'),
    CONTRIBUTOR_API_BASE_URL: z.string().default('/api/v1/contributor'),
    REPORT_API_BASE_URL: z.string().default('/api/v1/reporting'),
    VISUALIZATION_API_BASE_URL: z.string().default('/api/v4'),
});
